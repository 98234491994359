define('css2/helpers/oi-substring', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.oiSubstring = oiSubstring;
  function oiSubstring(params /*, hash*/) {
    if (typeof params === 'undefined' || params.length !== 3 || typeof params[0] !== 'string' || typeof params[1] !== 'number' || typeof params[2] !== 'number') {
      return 'Wrong params!';
    }
    return params[0].substring(params[1], params[2]);
  }

  exports.default = Ember.Helper.helper(oiSubstring);
});