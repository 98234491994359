define('css2/router', ['exports', 'css2/config/environment', 'ember-cli-matomo/mixins/page-view-tracker'], function (exports, _environment, _pageViewTracker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_pageViewTracker.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('index', { path: '/' });

    this.route('p2psearchorder', { path: '/p2psearchorder' }, function () {
      this.route('results', { path: ':handle' });
      this.route('noresults');
    });

    this.route('vesselschedule', { path: '/vesselschedule' }, function () {
      this.route('results');
    });

    this.route('csne', { path: '/csne' }, function () {
      this.route('results');
    });
    this.route('blanksailings', function () {
      this.route('report');
    });
    this.route('portintelligence', { path: '/portintelligence' }, function () {
      this.route('results');
    });

    this.route('login', { path: '/login' });

    this.route('reset');
    this.route('home');
    this.route('nopermissions');
  });

  exports.default = Router;
});