define('css2/components/oi-slim-mapbox', ['exports', 'css2/config/environment', 'css2/constants/events'], function (exports, _environment, _events) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    divId: 'map',
    mapId: null,
    tagName: 'div',
    classNames: ['oi-slim-mapbox'],
    currentMarker: null,
    currentPortSelectionIndex: -99,
    sortDefinition: null,
    fromMapSelectedPortScac: null,

    /**
     * Component's lifecycle hook - init
     */
    init: function init() {
      this._super();
      this.set('sortDefinition', ['carrier.name']);
    },


    someoneSelectedPort: Ember.observer('fromMapSelectedPortScac', function () {
      this.setPortSelected(this.get('fromMapSelectedPortScac'));
    }),

    sharedPortIndexGotChanged: Ember.observer('sharedPortIndex', function () {
      if (this.get('sharedPortIndex') == -99) {
        return;
      }
      var m = this.get('map');
      if (this.get('currentMarker')) {
        m.removeLayer(this.get('currentMarker'));
      }

      var list = this.get('allPortcallList');
      var targ = list[this.get('sharedPortIndex')];

      var lon = targ.longitude;
      var lat = targ.latitude;
      var portid = targ.portid || targ.port.id;

      // use the already drawn port to fix the longitude ("wrong world")
      /* eslint-disable no-underscore-dangle */
      for (var key in m._layers) {
        // check if the property/key is defined in the object itself, not in parent
        if (Object.prototype.hasOwnProperty.call(m._layers, key) && Object.prototype.hasOwnProperty.call(m._layers[key], '_latlng')) {
          if (m._layers[key].options.alt === portid) {
            // port match?
            lon = m._layers[key]._latlng.lng; // correct lon!
          }
        }
      }
      /* eslint-enable no-underscore-dangle */
      this.set('currentMarker', L.marker([lat, lon]).addTo(m));
    }),

    setPortSelected: function setPortSelected(locode) {
      var _this = this;

      for (var i = 0; i < this.get('mixedPortList').length; i++) {
        var p = this.get('mixedPortList')[i];
        var gottenLocode = p.locode || p.port.locode;
        var gottenPortId = p.portid || p.port.id;
        if (gottenLocode === locode) {
          _this.send('selPort', gottenPortId, i);
          return;
        }
      }
    },


    /**
     * Component's lifecycle hook - did receive args
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super();
      var index = this.get('currentSelectionIndex');

      // differs for vesselschedule and csne
      var currentPortlist = null;
      try {
        currentPortlist = this.get('mode') === 'vessel' ? this.get('selectedVoyage').voyages[0].portcalls : this.get('reorderedRotations')[index].portcalls;
      } catch (err) {
        currentPortlist = [];
      }
      var bestPortcalls = this.get('allPortcallList');
      var res = this.blend(bestPortcalls, currentPortlist);
      this.set('mixedPortList', res);
    },


    /**
     * Observer
     * Observer of currentSelectionIndex to set initial state after carrier change
     */
    scheduleChanged: Ember.observer('currentSelectionIndex', function () {

      this.set('fromMapSelectedPortScac', null);

      // delete previous marker
      var m = this.get('map');
      this.set('currentPortSelectionIndex', -99);
      if (this.get('currentMarker')) {
        m.removeLayer(this.get('currentMarker'));
      }
    }),

    /**
     * Observer
     * Observes the index to return the currently selected voyage
     */
    selectedVoyage: Ember.computed('currentSelectionIndex', 'schedule', function () {
      var currentSelectionIndex = this.get('currentSelectionIndex');
      var list = this.get('schedule.consolidatedVoyages');
      if (Ember.isNone(list)) {
        return [];
      }
      return list[currentSelectionIndex];
    }),

    selectedService: Ember.computed('currentSelectionIndex', function () {
      var currentSelectionIndex = this.get('currentSelectionIndex');
      var list = this.get('reorderedRotations');
      if (Ember.isNone(list)) {
        return [];
      }
      return list[currentSelectionIndex];
    }),

    /**
     * Returns meta portcall list as pre-calculated by Olaf.
     *
     * Note for future Martin: The 'meta' element delivers a positions array as well.
     * TODO: Consider to do the track drawing and POIs markers on the positions array
     * instead of rolling your own.
     *
     * As of 2017-11-13 the structure got changed - each payload now contains portcalls and positions array
     * at the upper level. Those arrays describing the allports/alltrackpos!
     * TODO: Consider to do the track drawing and POIs markers on the positions array
     * instead of rolling your own.
     */
    allPortcallList: Ember.computed('schedule', 'currentSelectionIndex', function () {
      var allPortcalls = this.get('schedule.portcalls');
      return allPortcalls;
    }),

    sortedRotations: Ember.computed.sort('schedule.rotations', 'sortDefinition'),

    reorderedRotations: Ember.computed('sortedRotations', function () {

      var requestedServiceId = this.get('schedule.id');
      var sortedRotations = this.get('sortedRotations');
      var gotcha = -99;
      sortedRotations.forEach(function (element, index) {
        if (element.id === parseInt(requestedServiceId)) {
          gotcha = index;
          return;
        }
      });
      if (gotcha !== -99) {
        var requestedService = sortedRotations[gotcha]; // memorize
        sortedRotations.splice(gotcha, 1); // cut 'em off
        sortedRotations.unshift(requestedService); // put 'em back at index 0
      }
      return sortedRotations;
    }),

    /**
     * Setup component
     * Initializes the map, assigns their events and stores their reference.
     */
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var _this2 = this;

        var map = L.map(this.get('divId'));
        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
          attribution: '<a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
          tileSize: 512,
          zoomOffset: -1,
          id: 'mapbox/streets-v11',
          accessToken: _environment.default.APP.mapbox.accessToken
        }).addTo(map);

        // Bind Events
        _events.MAP_EVENTS.forEach(function (event) {
          map.on(event, function (e) {
            return _this2.sendAction('on' + event, map, e);
          });
        });

        // store ref
        this.set('map', map);
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      Ember.run.next(function () {
        Ember.$('div.leaflet-control-attribution').children().eq(0).css('display', 'none');
        Ember.$('div.leaflet-control-attribution').children().eq(1).css('display', 'none');
      });
    },


    /**
     * Button checker
     * Enables/disables the previous button according to the current index
     */
    prevButtonDisabled: Ember.computed('currentSelectionIndex', function () {
      return this.get('currentSelectionIndex') < 1;
    }),

    /**
     * Button checker
     * Enables/disables the next button according to the current index
     */
    nextButtonDisabled: Ember.computed('currentSelectionIndex', function () {
      var list = this.get('schedule.consolidatedVoyages') || this.get('schedule.rotations');
      var l = list.length;

      var i = this.get('currentSelectionIndex');
      return i >= l - 1;
    }),

    /**
     *  Components actions
     */
    actions: {

      /**
       * Next button's action
       */
      inc: function inc() {
        Ember.get(this, 'incAction')();
      },


      /**
       * Previous button's action
       */
      dec: function dec() {
        Ember.get(this, 'decAction')();
      },
      mem: function mem(idx) {
        Ember.get(this, 'memAction')(idx);
      },


      /**
       * Sets the currentPortSelectionIndex and pins the port on the map
       * @param portId
       * @param index
       */
      selPort: function selPort(portId /*, index */) {
        // get the location of the selected port from allTracks
        var lat = null,
            lon = null;

        var allPortcalls = this.get('allPortcallList');
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = allPortcalls[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var portcall = _step.value;

            var portIdToCheck = portcall.portid || portcall.port.id; // either vessel or csne
            if (portIdToCheck && portIdToCheck === portId) {
              lat = portcall.latitude;
              lon = portcall.longitude;
            }
          }

          // get map and remove an existing pin
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        var m = this.get('map');
        if (this.get('currentMarker')) {
          m.removeLayer(this.get('currentMarker'));
        }

        if (lat && lon) {
          // set the new one
          // this is the time to check if the lon is on the "same earth"!!!
          // therefore we are retrieving the port dots from the map's layers
          // with already 'corrected' longitudes

          /* eslint-disable no-underscore-dangle */
          for (var key in m._layers) {
            // check if the property/key is defined in the object itself, not in parent
            if (Object.prototype.hasOwnProperty.call(m._layers, key) && Object.prototype.hasOwnProperty.call(m._layers[key], '_latlng')) {
              if (m._layers[key].options.alt === portId) {
                // port match?
                lon = m._layers[key]._latlng.lng; // correct lon!
              }
            }
          }
          /* eslint-enable no-underscore-dangle */
          this.set('currentMarker', L.marker([lat, lon]).addTo(m));
        }
      }
    },

    /**
     * Blend - assembles the mixed list of portcalls to be rendered later on
     */
    blend: function blend(all, curr) {
      var resultArray = [];
      for (var i = 0; i < all.length; i++) {
        var allElem = all[i];
        var matchedCurrent = this.hasMatch(allElem, curr);
        if (matchedCurrent !== null) {
          Ember.set(matchedCurrent, 'linkable', true);
          resultArray.push(matchedCurrent);
        } else {
          Ember.set(allElem, 'linkable', false);
          resultArray.push(allElem);
        }
      }
      return resultArray;
    },


    /**
     * Check for match
     */
    hasMatch: function hasMatch(bestElem, curr) {
      for (var i = 0; i < curr.length; i++) {
        var currElem = curr[i];
        if (bestElem.uuid === currElem.uuid) {
          return currElem;
        }
      }
      return null;
    }
  });
});