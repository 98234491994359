define('css2/models/pod', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    locode: _emberData.default.attr('string'),
    isocode: Ember.computed('locode', function () {
      var locode = this.get('locode');
      if (locode != null && locode.length > 0) {
        return locode.substr(0, 2);
      }
    })
  });
});