define('css2/controllers/csne', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),

    selectedCarrier: null,
    selectedService: null,

    allAvailableCarriers: Ember.computed('model', function () {
      return this.get('model');
    }),
    buttonDisabled: Ember.computed('selectedService', function () {
      return Ember.isNone(this.get('selectedService')) ? true : false;
    }),
    // observer to disable/enable 2nd dropdown
    carrierIsSet: Ember.computed('selectedCarrier', function () {
      return Ember.isNone(this.get('selectedCarrier'));
    }),

    selectedCarrierDidChange: Ember.observer('selectedCarrier', function () {
      this.set('selectedService', null);
    }),

    store: Ember.inject.service(),
    actions: {
      searchService: function searchService(term) {
        return this.store.query('service', { carrier_id: this.get('selectedCarrier.id'), s: term }); // eslint-disable-line camelcase
      },
      requestSearch: function requestSearch() {
        var service = this.get('selectedService');
        if (Ember.isPresent(service)) {
          this.transitionToRoute('csne.results', { queryParams: { id: service.id } });
        }
      }
    }
  });
});