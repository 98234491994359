define('css2/models/incident', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    start_date: _emberData.default.attr('momentdate'), // eslint-disable-line camelcase
    end_date: _emberData.default.attr('momentdate'), // eslint-disable-line camelcase
    carriers: _emberData.default.attr(),
    vessels: _emberData.default.attr(),
    ports: _emberData.default.attr(),

    carrierMatch: function carrierMatch(carrierId) {
      return this.get('carriers').includes(parseInt(carrierId));
    },
    vesselMatch: function vesselMatch(vesselId) {
      return this.get('vessels').includes(parseInt(vesselId));
    },
    portsMatch: function portsMatch(portId) {
      return this.get('ports').includes(parseInt(portId));
    },
    dateMatch: function dateMatch(etd, eta) {
      if (this.get('start_date')) {
        if (!this.get('end_date')) {
          if (this.get('start_date') <= eta) {
            return true;
          }
        }
        if (this.get('end_date')) {
          if (dateRangeOverlaps(etd, eta, this.get('start_date'), this.get('end_date'))) {
            return true;
          }
        }
      }
      return false;
    }
  });


  function dateRangeOverlaps(aStart, aEnd, bStart, bEnd) {
    if (aStart <= bStart && bStart <= aEnd) return true; // b starts in a
    if (aStart <= bEnd && bEnd <= aEnd) return true; // b ends in a
    if (bStart <= aStart && aEnd <= bEnd) return true; // a in b
    if (aStart <= bStart && bEnd <= aEnd) return true; // b in a
    return false;
  }
});