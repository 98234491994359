define('css2/components/oi-mapbox-geojson', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function oisign(num) {
    // bc IE11 does not support Math.sign()
    return num < 0 ? -1 : num > 0 ? 1 : 0;
  } /* global L */
  exports.default = Ember.Component.extend({

    classNameBindings: ['isLoaded'],
    bestTrackObject: null,
    markersArray: null,
    currentIndex: -1,
    boundingbox: false,

    /**
     * Observer of index to fire init() when user selects a new carrier
     */
    indexChanged: Ember.observer('currentIndex', function () {

      // first: delete the track and the drawn markers (dots)
      this.get('map').removeLayer(this.get('bestTrackObject'));
      var markersArray = this.get('markersArray');
      var _this = this;
      markersArray.forEach(function (marker) {
        _this.get('map').removeLayer(marker);
      });

      // then call init()
      this.init();
    }),

    setBounds: function setBounds() {
      try {
        this.get('map').fitBounds(this.calcBounds());
      } catch (err) {
        // TODO: Notify Raven!
      }
    },


    // this is where the actual drawing of the pre-calculated stuff happens
    // see classNameBindings of Ember component
    isLoaded: Ember.computed('map', 'bestTrackObject', function () {

      var map = this.get('map');
      var bestTrackObject = this.get('bestTrackObject');
      var boundingbox = this.get('boundingbox');

      if (!Ember.isEmpty(map) && !Ember.isEmpty(bestTrackObject)) {

        // 1. get track being created in setup() and add it to the map
        this.get('bestTrackObject').addTo(map);

        // 2. re-calculate map bounds
        if (boundingbox === true) {
          this.setBounds();
        }

        // get the prepared markers and draw them to the map
        // we need to draw the regular ones at first to not overlap
        // the special ones which are being drawn within the second loop.
        // and that's why we iterate two times the 'old style' way instead of
        // forEach

        var markersArray = this.get('markersArray');

        if (!Ember.isEmpty(markersArray) && markersArray.length > 0) {
          markersArray.forEach(function (marker) {
            if (marker.options.icon.options.className === 'svg-marker') {
              marker.addTo(map);
            }
          });
        }

        if (!Ember.isEmpty(markersArray) && markersArray.length > 0) {
          markersArray.forEach(function (marker) {
            if (marker.options.icon.options.className === 'svg-marker-sel') {
              marker.addTo(map);
            }
          });
        }
        return true;
      }
      return false;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.sortKeys = ['carrier'];
      // assemble the bestTrack object and store it as instance variable
      var bestTrackObject = L.geoJson(this.get('bestTrackGJ.geojson'), {
        style: L.mapbox.simplestyle.style
      });
      this.set('bestTrackObject', bestTrackObject);

      // now create the markers and store them in an instance variable as well
      var markersArray = [];
      var uniquePortcalls = this.get('bestTrackGJ.portcalls');

      var _this = this;
      var oldLongitude = 0.0;

      uniquePortcalls.forEach(function (portcall) {

        // longitude correction - don't get me started
        var newLongitude = portcall.longitude;
        if (Math.abs(newLongitude - oldLongitude) > 180.0) {
          newLongitude = newLongitude + 360 * oisign(oldLongitude);
        }
        oldLongitude = newLongitude;

        // we draw two types of markers - the normal one which is part of the 'best track' (blue outline, white fill)
        // and the special (blue outline, white fill, centered blue dot) which we use to mark a port as being called
        // by the currently selected
        var portlocode = portcall.locode || portcall.port.locode;
        var portid = portcall.portid || portcall.port.id;

        var innerCircle = _this.isInCurrentCarriersPortcalls(portlocode) ? '<circle cx="10" cy="10" r="5" opacity="1.0" fill="#2185D0" stroke="#2185D0" stroke-width="0"/>' : '';
        var className = innerCircle.length > 0 ? 'svg-marker-sel' : 'svg-marker';
        var that = _this;
        var marker = new L.Marker(new L.LatLng(portcall.latitude, newLongitude), {
          title: portlocode,
          alt: portid, // using the portid to compare later on (related to longitude correction)
          icon: L.divIcon({
            className: className, // we use the className to distinguish between the two marker types (carrier and best) later on
            html: '' + ('<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">' + '<circle cx="10" cy="10" r="8" opacity="1.0" fill="#ffffff" stroke="#2185D0" stroke-width="2"/>') + innerCircle + '</svg>',
            iconSize: [20, 20]
          }),
          draggable: false,
          clickable: true
        });

        if (_this.isInCurrentCarriersPortcalls(portlocode)) {
          marker.on('click', function (e) {
            that.sendAction('clickAction', e.target.options.title);
          });
        }
        markersArray.push(marker);
      });

      this.set('markersArray', markersArray);
    },


    sortedVoyages: Ember.computed.sort('voyages', 'sortKeys'),

    isInCurrentCarriersPortcalls: function isInCurrentCarriersPortcalls(locode) {

      //var voyages = this.get('voyages');
      var voyages = this.get('sort') ? this.get('sortedVoyages') : this.get('voyages');
      var currIndex = this.get('currentIndex');
      var currentVoyage = voyages[currIndex];

      if (currentVoyage) {
        var portcalls = void 0;
        if (currentVoyage.voyages) {
          // vessel
          portcalls = currentVoyage.voyages[0].portcalls;
        } else {
          //csne
          portcalls = currentVoyage.portcalls;
        }
        for (var i = 0; i < portcalls.length; i++) {
          var plocode = portcalls[i].locode || portcalls[i].port.locode;
          if (plocode === locode) {
            return true;
          }
        }
      }
      return false;
    },
    willDestroyElement: function willDestroyElement() {
      var bestTrackObject = this.get('bestTrackObject');
      //    let markersArray = this.get('markersArray');


      var map = this.get('map');
      if (map && bestTrackObject) {
        map.removeLayer(bestTrackObject);
      }
    },


    /*
    popup: Ember.on('didRender', function () {
      if (this.get('is-open')) {
        this.get('bestTrackObject').openPopup();
      }
    }),
    */

    calcBounds: function calcBounds() {

      var bounds = new L.LatLngBounds();
      /* let currTrackObject = */
      L.geoJson(this.get('bestTrackGJ.geojson'), {
        onEachFeature: function onEachFeature(feature) {
          feature.geometry.coordinates.forEach(function (pos) {
            bounds.extend([pos[1], pos[0]]);
          });
        }
      });
      return bounds;
    }
  });
});