define('css2/models/p2psearchorder', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    completed: _emberData.default.attr(),
    handle: _emberData.default.attr(),
    pod: _emberData.default.attr(),
    pol: _emberData.default.attr(),
    polDict: _emberData.default.attr(),
    podDict: _emberData.default.attr(),
    results: _emberData.default.hasMany('p2pvoyage', { embedded: true }),
    eta: _emberData.default.attr(),
    etd: _emberData.default.attr(),
    weeks: _emberData.default.attr(),
    allroutes: _emberData.default.attr(),
    nolimit: _emberData.default.attr()

  });
});