define('css2/services/raven', ['exports', 'ember-cli-sentry/services/raven'], function (exports, _raven) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _raven.default.extend({
    unhandledPromiseErrorMessage: '',
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    ignoreErrors: [// has to be modifiable, else service init fails
    'Network request failed'],
    captureMessage: function captureMessage() {
      var _console;

      if (this.get('isRavenUsable')) {
        return this._super.apply(this, arguments);
      }
      // default ember-cli-sentry behaviour is to throw an error in development
      // mode, but it suffices if captureException does that...there should be a
      // method to send something to raven even when the situation is recoverable.
      (_console = console).warn.apply(_console, arguments); // eslint-disable-line no-console
      return true;
    },
    setUserContext: function setUserContext() {
      var _console2;

      if (this.get('isRavenUsable')) {
        return this._super.apply(this, arguments);
      }
      (_console2 = console).warn.apply(_console2, arguments); // eslint-disable-line no-console
      return true;
    }
  });
});