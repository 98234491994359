define('css2/helpers/oi-filter-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.oiFilterOptions = oiFilterOptions;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function oiFilterOptions(params /*, hash*/) {
    if (_typeof(params[0]) !== 'object') {
      return 'Wrong type of arguments - need default ';
    }

    var filterOptions = params[0];
    if (typeof filterOptions.forEach === 'function') {
      filterOptions.forEach(function (option) {
        if (option.value == '') {
          option.label = 'Select...';
        }
      });
    }
    return filterOptions;
  }

  exports.default = Ember.Helper.helper(oiFilterOptions);
});