define('css2/routes/p2psearchorder/results', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'css2/custom/pollster', 'moment'], function (exports, _authenticatedRouteMixin, _pollster, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MAX_NUMBER_TRY = 35;

  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    currentHandle: null,
    completed: false,

    model: function model(params) {
      var _this2 = this;

      this.set('completed', false);
      this.set('currentHandle', params.handle); // we need this id to feed the pollster with...
      return this.get('store').findRecord('p2psearchorder', params.handle).then(function (results) {
        return results;
      }).catch(function (reason) {
        _this2.set('completed', true);
        _this2.set('errorMessage', reason.error_description || reason.error);
        return null;
      });
    },

    /* eslint-disable max-statements */
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (this.get('completed') && Ember.isNone(model)) {
        controller.set('completed', true);
        controller.set('error', true);
        return;
      }

      // may be checked from a previous request, so reset!
      controller.set('showOnlyDirect', false);

      var modelEta = model.get('eta');
      var modelEtd = model.get('etd');
      if (model.get('completed') === false) {
        var counter = 0;
        if (Ember.isNone(this.get('pollster'))) {
          var _this = this;
          this.set('pollster', _pollster.default.create({
            onPoll: function onPoll() {
              if (_this.get('completed') === true) {
                counter = 0;
                _this.get('pollster').stop();
                return;
              }
              if (_this.get('completed') === false && counter < MAX_NUMBER_TRY) {
                counter++;
                _this.get('store').findRecord('p2psearchorder', _this.get('currentHandle'), { reload: true }).then(function (result) {
                  _this.set('completed', result.get('completed'));
                  controller.set('completed', result.get('completed'));
                  controller.set('entries', result.get('results'));
                  controller.initialize();
                });
              } else {
                counter = 0;
                controller.set('completed', true);
                _this.set('completed', true);
                _this.get('pollster').stop();
              }
            }
          }));
        }

        this.get('pollster').stop();
        this.get('pollster').start();
      } else {

        // we're done - model hook finally delivered a complete data set
        // so, tell the controller & pass the model to the controller

        controller.set('completed', model.get('completed') || 'completed');
        this.set('completed', model.get('completed') || 'completed');
        controller.set('entries', model.get('results'));

        // get time range from model
        var modelWeeks = model.get('weeks');

        if (!modelEta) {
          controller.set('queryStart', modelEtd);
          controller.set('queryEnd', (0, _moment.default)(modelEtd, 'YYYY-MM-DD').add(modelWeeks * 7, 'days').format('YYYY-MM-DD'));
        } else {
          controller.set('queryStart', (0, _moment.default)(modelEta, 'YYYY-MM-DD').subtract(modelWeeks * 7, 'days').format('YYYY-MM-DD'));
          controller.set('queryEnd', modelEta);
        }
      }

      var parentController = this.controllerFor('p2psearchorder');
      parentController.setProperties({
        selectedPolPort: model.get('polDict'),
        selectedPodPort: model.get('podDict'),
        dateModeIsArrival: !!modelEta,
        bestRoutesOnly: !model.get('allroutes'),
        weeks: model.get('weeks'),
        selectedDate: modelEta || modelEtd,
        hasResult: true
      });

      controller.set('currentHandle', this.get('currentHandle'));
      controller.initialize();
    },
    /*eslint-enable max-statements */

    deactivate: function deactivate() {
      // being called on exiting the route!
      if (Ember.isPresent(this.get('pollster'))) {
        this.get('pollster').stop();
      }
    }

  });
});