define('css2/routes/portintelligence/results', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'moment'], function (exports, _authenticatedRouteMixin, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    queryParams: {
      port: {
        refreshModel: true
      },
      weeks: {
        refreshModel: true
      },
      startdate: {
        refreshModel: true
      }
    },

    model: function model(params) {
      if (Ember.isPresent(params.port)) {
        return this.get('store').query('portintelligence', params);
      }
      return null;
    },
    createConsolidatedVesselList: function createConsolidatedVesselList(vessels) {
      var consolidatedVessels = [];
      var etas = void 0,
          closestETA = null;
      vessels.forEach(function (vessel) {
        closestETA = null;
        etas = Array((0, _moment.default)(vessel.get('eta')));
        closestETA = _moment.default.min(etas);
        vessel.set('eta', closestETA);
        consolidatedVessels.push({
          'vessel': vessel,
          'closestETA': closestETA,
          'timezone': vessel.get('timezone')
        });
      });

      return consolidatedVessels;
    },
    setupController: function setupController(controller, model) {
      var parentController = this.controllerFor('portintelligence');
      this._super.apply(this, arguments);

      if (Ember.isNone(model)) {
        return;
      }
      parentController.set('selectedPort', this.store.findRecord('port', model.query.port));
      controller.set('selectedPort', parentController.get('selectedPort'));
      Ember.set(controller, 'consolidatedVesselList', this.createConsolidatedVesselList(model));
    },
    resetController: function resetController(controller, isExiting) {
      this.store.unloadAll('portintelligence');
      if (isExiting) {
        controller.set('selectedPort', null);
      }
    }
  });
});