define('css2/controllers/vesselschedule/results', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    currentSelectionIndex: 0, // which service is selected initially?
    fromMapSelectedPortScac: null,
    sortDefinition: null,
    viewMode: 2, // this is a variable to controll template's style
    currentUser: Ember.inject.service('current-user'),

    init: function init() {
      this._super();
      this.set('sortDefinition', ['carrier']);
    },


    /*
    currentSelectionIndexView: computed('currentSelectionIndex', function () {
      return this.get('currentSelectionIndex') + 1;
    }),
    */

    schedules: Ember.computed('model', function () {
      var schedules = this.get('model');
      return schedules;
    }),

    incidents: Ember.computed('end', 'start', function () {
      var _this = this;

      return this.store.peekAll('incident').filter(function (incident) {
        return _this.incidentIsMatching(incident);
      });
    }),

    incidentIsMatching: function incidentIsMatching(incident) {
      var isMatching = this.get('schedules').map(function (schedule) {
        if (!incident.dateMatch(schedule.get('voyageEarliestDate'), schedule.get('voyageLatestDate'))) {
          return false;
        }
        if (incident.get('carriers').length > 0) {
          if (!(incident.get('carriers').filter(function (carrier) {
            return schedule.get('allVoyageCarriers').includes(carrier);
          }).length > 0)) {
            return false;
          }
        }
        if (incident.get('vessels').length > 0) {
          if (!incident.vesselMatch(parseInt(schedule.get('vessel.id')))) {
            return false;
          }
        }
        if (incident.get('ports').length > 0) {
          if (!(incident.get('ports').filter(function (port) {
            return schedule.get('allVoyagePorts').includes(port);
          }).length > 0)) {
            return false;
          }
        }
        return true;
      })[0];

      return isMatching;
    },

    /**
     * Every schedule brings its own positions and portcalls array
     * we can use accross all services sharing this schedule.
     * We transform this into a new structure to feed the
     * oi-mapbox-geojson with and to draw the track on a map.
     */
    bestTrackGJ: Ember.computed('model', function () {
      var schedules = this.get('model');

      var _process = this.process(schedules),
          newArray = _process.newArray,
          portcalls = _process.portcalls; // actual transforming

      return {
        geojson: [{
          'type': 'Feature',
          'geometry': {
            'type': 'LineString',
            'coordinates': newArray
          },
          'properties': {
            'stroke': '#2185D0',
            'stroke-width': 5,
            'stroke-opacity': 1.0
          }
        }],
        portcalls: portcalls
      };
    }),

    /**
     * Transforms the current schedules positions array to a simpler one
     * only containing the ccords and returns them along with a pass-through
     * portcalls array. Both structures will be used to assemble a nice
     * GeoJSOn object later on.
     *
     * @param {*} schedules
     */
    process: function process(schedules) {
      var positions = null;
      var portcalls = null;

      schedules.forEach(function (schedule) {
        positions = schedule.get('positions');
        portcalls = schedule.get('portcalls');
      });

      var newArray = [];
      positions.forEach(function (pos) {
        newArray.push([pos.longitude, pos.latitude]);
      });
      return { newArray: newArray, portcalls: portcalls };
    },


    actions: {

      // currently not wired - this was here to change the result view
      toggleViewMode: function toggleViewMode() {

        var v = this.get('viewMode');
        if (v < 2) {
          this.set('viewMode', v + 1);
        } else {
          this.set('viewMode', 0);
        }
      },
      toggleAccordion: function toggleAccordion(e) {
        var chevron = document.getElementById(e);
        if (chevron.style.transform === 'rotate(180deg)') {
          chevron.style.transform = 'rotate(0deg)';
        } else {
          chevron.style.transform = 'rotate(180deg)';
        }
      },
      selectRelatedService: function selectRelatedService(index) {
        this.set('currentSelectionIndex', index);
        this.set('sharedPortIndex', -99);
        this.set('fromMapSelectedPortScac', null);
      },
      incIndex: function incIndex() {
        this.set('currentSelectionIndex', this.get('currentSelectionIndex') + 1);
      },
      decIndex: function decIndex() {
        this.set('currentSelectionIndex', this.get('currentSelectionIndex') - 1);
      },
      click: function click(arg) {
        this.set('fromMapSelectedPortScac', arg);
      },
      selPort: function selPort(portid, i) {
        this.set('portIndex', i);
        this.set('sharedPortIndex', i);
      }
    },

    flush: function flush() {
      this.set('id', null);
      this.set('start', null);
      this.set('end', null);
    }
  });
});