define('css2/controllers/csne/results', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    csne: Ember.inject.controller(),
    currentSelectionIndex: 0,
    sortDefinition: null,
    fromMapSelectedPortScac: null,
    currentUser: Ember.inject.service('current-user'),

    init: function init() {
      this._super();
      this.set('sortDefinition', ['carrier.name']);
    },


    vesselstart: Ember.computed(function () {
      return (0, _moment.default)().format('YYYY-MM-DD');
    }),

    vesselend: Ember.computed(function () {
      return (0, _moment.default)().add(28, 'days').format('YYYY-MM-DD');
    }),

    /*
    currentSelectionIndexView: computed('currentSelectionIndex', function () {
      return this.get('currentSelectionIndex') + 1;
    }),
    */

    sortedRotations: Ember.computed.sort('model.rotations', 'sortDefinition'),

    selectedCarrierFromUpperController: Ember.computed('sortedRotations', function () {
      var requestedCarrierId = this.get('model.carrierId');
      var carrierList = this.get('csne.model');
      var returnElement = null;
      carrierList.forEach(function (element) {
        if (parseInt(element.id) === parseInt(requestedCarrierId)) {
          // parseInt as in "we never know"
          returnElement = element;
        }
      });
      return returnElement;
    }),

    // now that we've got the services sorted by carrier thanks to the ember magic 🔼
    // we need to find out the requested one and put 'em at index 0

    reorderedRotations: Ember.computed('sortedRotations', function () {

      var requestedServiceId = this.get('model.id');
      var sortedRotations = this.get('sortedRotations');
      var gotcha = -99;
      var index = 0;
      sortedRotations.forEach(function (element) {
        if (element.id === parseInt(requestedServiceId)) {
          gotcha = index;
          return;
        }
        index++;
      });
      if (gotcha !== -99) {
        var requestedService = sortedRotations[gotcha]; // memorize
        sortedRotations.splice(gotcha, 1); // cut 'em off
        sortedRotations.unshift(requestedService); // put 'em back at index 0
      }
      return sortedRotations;
    }),

    /**
     * Every provider brings its own positions and portcalls array
     * we can use accross all services having this schedule.
     * We transform this into a new structure to feed the
     * oi-mapbox-geojson with and to draw the track on a map.
     */
    bestTrackGJ: Ember.computed('model', function () {
      var schedule = this.get('model');

      var _process = this.process(schedule),
          newArray = _process.newArray,
          portcalls = _process.portcalls; // actual transforming

      return {
        geojson: [{
          'type': 'Feature',
          'geometry': {
            'type': 'LineString',
            'coordinates': newArray
          },
          'properties': {
            'stroke': '#2185D0',
            'stroke-width': 5,
            'stroke-opacity': 1.0
          }
        }],
        portcalls: portcalls
      };
    }),

    /**
     * Transforms the current schedules positions array to a simpler one
     * only containing the ccords and returns them along with a pass-through
     * portcalls array. Both structures will be used to assemble a nice
     * GeoJSOn object later on.
     *
     * @param {*} schedules
     */
    process: function process(schedule) {

      var positions = null;
      var portcalls = null;

      positions = schedule.get('positions');
      portcalls = schedule.get('portcalls');

      var newArray = [];
      positions.forEach(function (pos) {
        newArray.push([pos.longitude, pos.latitude]);
      });
      return { newArray: newArray, portcalls: portcalls };
    },
    oisign: function oisign(num) {
      // bc IE11 does not support Math.sign()
      return num < 0 ? -1 : num > 0 ? 1 : 0;
    },


    actions: {
      toggleAdditionalInfo: function toggleAdditionalInfo() {
        var chevron = document.getElementById('additional-info-btn');
        var expanded = !!Ember.$('#additional-info').width();
        if (expanded) {
          Ember.$('#primary-info').animate({ width: '100%' }, 500);
          Ember.$('#additional-info').animate({ width: '0%' }, { duration: 500, queue: false }).fadeOut('fast');
          chevron.style.transform = 'rotate(0deg)';
        } else {
          Ember.$('#primary-info').animate({ width: '75%' }, 500);
          Ember.$('#additional-info').animate({ width: '25%' }, { duration: 500, queue: false }).fadeIn('fast');
          chevron.style.transform = 'rotate(180deg)';
        }
      },
      selectRelatedService: function selectRelatedService(index) {
        this.set('currentSelectionIndex', index);
        this.set('sharedPortIndex', -99);
        this.set('fromMapSelectedPortScac', null);
      },
      incIndex: function incIndex() {
        this.set('currentSelectionIndex', this.get('currentSelectionIndex') + 1);
      },
      decIndex: function decIndex() {
        this.set('currentSelectionIndex', this.get('currentSelectionIndex') - 1);
      },
      memIndex: function memIndex(idx) {
        this.set('sharedPortIndex', idx);
      },
      click: function click(arg) {
        this.set('fromMapSelectedPortScac', arg);
      },
      setSelectedPortIndexAction: function setSelectedPortIndexAction(index) {
        this.set('portIndex', index);
      },
      selPort: function selPort(portid, i) {
        this.set('portIndex', i);
        this.set('sharedPortIndex', i);
      }
    },

    currentlySelectedService: Ember.computed('currentSelectionIndex', 'reorderedRotations', function () {
      var index = this.get('currentSelectionIndex');
      return this.get('reorderedRotations')[index];
    }),

    flush: function flush() {
      this.set('id', null);
    }
  });
});