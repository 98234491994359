define('css2/authenticators/oauth2', ['exports', 'css2/config/environment', 'ember-simple-auth/authenticators/oauth2-password-grant'], function (exports, _environment, _oauth2PasswordGrant) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2PasswordGrant.default.extend({

    serverTokenEndpoint: '',
    clientId: null,
    applicationService: Ember.inject.service(),

    init: function init() {
      this._super();
      this.serverTokenEndpoint = this.get('applicationService.apiHost') + '/o/token/';
      this.serverTokenRevocationEndpoint = this.get('applicationService.apiHost') + '/o/revoke-token/';
      this.clientId = _environment.default.APP.CLIENT_ID;
    },


    authenticate: function authenticate(identification, password) {
      var _this = this;

      var scope = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var data = {
          'grant_type': 'password',
          'username': identification,
          'password': password
        };
        var serverTokenEndpoint = _this.get('serverTokenEndpoint');
        var scopesString = Ember.makeArray(scope).join(' ');
        if (!Ember.isEmpty(scopesString)) {
          data.scope = scopesString;
        }
        _this.makeRequest(serverTokenEndpoint, data).then(function (response) {
          Ember.run(function () {
            /* eslint-disable no-underscore-dangle */
            var expiresAt = _this._absolutizeExpirationTime(response['expires_in']);
            _this._scheduleAccessTokenRefresh(response['expires_in'], expiresAt, response['refresh_token']);
            /* eslint-enable no-underscore-dangle */
            if (!Ember.isEmpty(expiresAt)) {
              Ember.merge(response, { 'expires_at': expiresAt });
            }
            resolve(response);
          });
        }, function (xhr) {
          Ember.run(null, reject, xhr.responseJSON || xhr.responseText);
        });
      });
    },

    makeRequest: function makeRequest(url, data) {
      var options = {
        url: url,
        data: data,
        type: 'POST',
        dataType: 'json',
        contentType: 'application/x-www-form-urlencoded'
      };
      var clientId = this.get('clientId');

      if (!Ember.isEmpty(clientId)) {
        var base64ClientId = window.btoa(clientId.join(':'));
        Ember.merge(options, {
          headers: {
            Authorization: 'Basic ' + base64ClientId
          }
        });
      }
      return Ember.$.ajax(options);
    }

  });
});