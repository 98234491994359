define('css2/routes/csne', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    currentUser: Ember.inject.service('current-user'),

    beforeModel: function beforeModel() {
      if (!this.get('currentUser.user.permission.csn')) {
        return this.transitionTo(this.get('currentUser.firstAllowedRoute'));
      }
      return this._super.apply(this, arguments);
    },


    model: function model() {
      return this.get('store').findAll('carrier');
    },

    activate: function activate() {
      Ember.$('body').addClass('csne');
      Ember.$('body').removeClass('vesselschedules');
      Ember.$('body').removeClass('p2p');
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('selectedCarrier', null);
        controller.set('selectedService', null);
        controller.set('currentSelectionIndex', 0);
      }
    }
  });
});