define('css2/adapters/portintelligence', ['exports', 'css2/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    namespace: 'cswapi/v3', // FIXME Should be aligned with official api

    pathForType: function pathForType() {
      return 'port-intelligence-arrivals';
    }
  });
});