define('css2/routes/reset', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var isInvite = params.invite;
      return Ember.RSVP.hash({ isInvite: isInvite });
    },


    resetController: function resetController(controller) {
      controller.setProperties({
        errorMessage: '',
        notificationType: null,
        currentPassword: '',
        newpasswordone: '',
        newpasswordtwo: ''
      });
    }

  });
});