define('css2/models/leg', ['exports', 'moment', 'ember-data'], function (exports, _moment, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    pod: _emberData.default.attr(),
    pol: _emberData.default.attr(),
    serviceName: _emberData.default.attr('string'),
    serviceId: _emberData.default.attr('number'),
    serviceCode: _emberData.default.attr('string'),
    voyageNumber: _emberData.default.attr('string'),
    etd: _emberData.default.attr('momentdate'),
    eta: _emberData.default.attr('momentdate'),
    etdLocal: _emberData.default.attr('string'),
    etaLocal: _emberData.default.attr('string'),
    vessel: _emberData.default.attr(),

    // ignores timezone
    pureEtd: Ember.computed('etd', function () {
      return _moment.default.utc(this.get('etd')).format('YYYY-MM-DD HH:mm');
    }),

    pureEtdLocal: Ember.computed('etdLocal', function () {
      return _moment.default.parseZone(this.get('etdLocal')).format('YYYY-MM-DD HH:mm');
    }),

    pureEta: Ember.computed('eta', function () {
      return _moment.default.utc(this.get('eta')).format('YYYY-MM-DD HH:mm');
    }),

    pureEtaLocal: Ember.computed('etaLocal', function () {
      return _moment.default.parseZone(this.get('etaLocal')).format('YYYY-MM-DD HH:mm');
    })

  });
});