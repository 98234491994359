define('css2/adapters/servicenetwork', ['exports', 'css2/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    addTrailingSlashes: false,
    urlForFindRecord: function urlForFindRecord(id, modelName /* snapshot */) {
      var regularUrl = this._super.apply(this, arguments);

      if (modelName !== 'servicenetwork') {
        console.error('servicenetwork adapter used with different model'); // eslint-disable-line no-console
        return regularUrl; // ember should never let this happen
      }
      // Because the Back-End is implmented in some non-standard way
      // (get_queryset returns a model instance, not a queryset), the regular
      // django lookup-by-id logic fails (when trying to filter the queryset)
      // and find-record-by-id works with this strange workaround:
      return regularUrl.split(id)[0] + '?id=' + id;
    }
  });
});