define('css2/routes/vesselschedule', ['exports', 'moment', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _moment, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    currentUser: Ember.inject.service('current-user'),

    beforeModel: function beforeModel() {
      if (!this.get('currentUser.user.permission.vss')) {
        return this.transitionTo(this.get('currentUser.firstAllowedRoute'));
      }
      return this._super.apply(this, arguments);
    },


    setupController: function setupController(controller /* , model */) {

      this._super.apply(this, arguments);
      controller.set('start', (0, _moment.default)(new Date()).format('YYYY-MM-DD'));
      controller.set('end', (0, _moment.default)(new Date(+new Date() + 12096e5 * 2)).format('YYYY-MM-DD'));
      controller.set('minDateStart', new Date());
      controller.set('maxDateStart', new Date(+new Date() + 12096e5 * 6));
      controller.set('minDateEnd', new Date(+new Date() + 86400));
      controller.set('maxDateEnd', new Date(+new Date() + 12096e5 * 6));
    },

    resetController: function resetController(controller, isExiting /* , transition */) {
      if (isExiting) {
        controller.set('selectedVessel', null);
        controller.set('start', null);
        controller.set('end', null);
        controller.set('minDateStart', null);
        controller.set('maxDateStart', null);
      }
    }
  });
});