define('css2/components/oi-blanksailings', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var searchPlaceholder = 'Search...';

    var SAILING_STATE = {
        0: 'Blanked Sailings',
        1: 'Scheduled Sailings',
        2: 'No Sailings detected'
    };

    var filterFn = function filterFn(cellString, filterString, rowObj) {
        if (rowObj && rowObj.serviceCode && rowObj.serviceName && rowObj.servicePool && filterString) {
            var code = rowObj.serviceCode.toLowerCase();
            var name = rowObj.serviceName.toLowerCase();
            var pool = rowObj.servicePool.toLowerCase();
            var stringToFilter = filterString.toLowerCase().trim();
            return code.indexOf(stringToFilter) !== -1 || name.indexOf(stringToFilter) !== -1 || pool.indexOf(stringToFilter) !== -1;
        } else {
            return true;
        }
    };

    var downloadCSVFile = function downloadCSVFile(data) {
        var csvFile = new Blob([data], { type: 'text/csv' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(csvFile);
        var fileName = new Date();
        fileName = fileName.toISOString().split('T')[0];
        link.download = 'Blank sailings ' + fileName + '.csv';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
    };

    var getHeader = function getHeader(data) {
        var keys = Object.keys(data).join(',');
        return keys;
    };

    exports.default = Ember.Component.extend({
        hasPermission: Ember.computed('report.services', function () {
            var services = this.get('report.services');
            return services.every(function (service) {
                return service.status;
            });
        }),

        columns: Ember.computed('report.weeks', function () {
            var result = [{
                'propertyName': 'alliance',
                'title': 'Alliance',
                'filterWithSelect': true,
                'className': 'widthCell'
            }, {
                'propertyName': 'carrier',
                'title': 'Carrier',
                'filterWithSelect': true,
                'className': 'widthCell',
                'filterPlaceholder': searchPlaceholder
            }, {
                'propertyName': 'servicePool',
                'title': 'Service/Pool',
                'className': 'service-pool',
                'filterPlaceholder': searchPlaceholder,
                'component': 'oi-models-table/oi-service',
                'filterFunction': filterFn
            }, {
                'propertyName': 'direction',
                'title': 'Direction',
                'filterWithSelect': true,
                'className': 'widthCell'
            }, {
                'propertyName': 'firstPol',
                'title': 'First POL',
                'className': 'widthCell',
                'disableFiltering': true
            }, {
                'propertyName': 'lastPod',
                'title': 'Last POD',
                'className': 'widthCell blanksailingseparator',
                'disableFiltering': true
            }];

            var weeks = this.get('report.weeks');
            if (weeks) {
                for (var value in weeks) {
                    result.push({
                        'propertyName': 'week_' + value,
                        'title': '' + (weeks[value] ? weeks[value].replace('-', ' ') : '-'),
                        'filterWithSelect': true,
                        'component': 'oi-models-table/oi-cell',
                        'className': 'widthCell'
                    });
                }
            }
            return result;
        }),
        data: Ember.computed('report.services', function () {
            var weeks = this.get('report.weeks');
            var services = this.get('report.services');
            var result = [];

            if (services && weeks) {
                services.forEach(function (service) {
                    var sObj = {
                        alliance: service.alliance,
                        serviceId: service.service_id,
                        serviceCode: service.service_code,
                        serviceName: service.service_name,
                        servicePool: service.service_pool,
                        carrier: service.carrier,
                        direction: service.direction,
                        firstPol: service.first_pol,
                        lastPod: service.last_pod
                    };

                    if (service.status) {
                        for (var week in weeks) {
                            sObj['week_' + week] = service.status[week] == undefined ? '-' : SAILING_STATE[service.status[week]];
                        }
                    }

                    result.push(sObj);
                });
            }
            return result;
        }),

        filteredData: Ember.computed(function () {
            return this.get('data');
        }),

        actions: {
            updateData: function updateData(e) {
                this.set('filteredData', e.filteredContent);
            },
            download: function download() {
                var line = '';
                var header = getHeader(this.get('filteredData')[0]);
                var dataToDownload = dataToDownload + header + '\n';
                this.get('filteredData').forEach(function (element) {
                    line = '';
                    for (var key in element) {
                        line = line + element[key] + ',';
                    }
                    dataToDownload = dataToDownload + line + '\n';
                });

                downloadCSVFile(dataToDownload);
            }
        }
    });
});