define('css2/helpers/oi-flag-adapter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.oiFlagAdapter = oiFlagAdapter;
  function oiFlagAdapter(params /*, hash*/) {
    var isocode = params[0];

    var retValue = '';

    switch (isocode) {
      case 'ak':
        retValue = 'us';
        break;
      case 'bq':
        retValue = 'an';
        break;
      case 'cc':
        retValue = 'cr';
        break;
      case 'cs':
        retValue = 'rs';
        break;
      case 'cx':
        retValue = 'au';
        break;
      case 'xa':
        retValue = 'pt';
        break;
      case 'xm':
        retValue = 'pt';
        break;
      default:
        retValue = isocode;
        break;
    }

    return retValue;
  }

  exports.default = Ember.Helper.helper(oiFlagAdapter);
});