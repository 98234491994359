define('css2/routes/p2psearchorder', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    currentUser: Ember.inject.service('current-user'),

    beforeModel: function beforeModel() {
      if (!this.get('currentUser.user.permission.p2p')) {
        return this.transitionTo(this.get('currentUser.firstAllowedRoute'));
      }
      return this._super.apply(this, arguments);
    },
    afterModel: function afterModel() {
      if (!this.get('currentUser.user.permission.p2p')) {
        return this.transitionTo(this.get('currentUser.firstAllowedRoute'));
      }
      return this._super.apply(this, arguments);
    },


    setupController: function setupController(controller /* , model */) {
      this._super.apply(this, arguments);
      controller.set('minDate', new Date());
      //controller.set('maxDate', new Date(+new Date() + (12096e5 * 6)));
    },

    resetController: function resetController(controller, isExiting /* , transition */) {
      if (isExiting) {
        controller.set('minDate', null);
        //controller.set('maxDate', null);
      }
    }
  });
});