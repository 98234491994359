define('css2/models/vesselschedule', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    vessel: _emberData.default.belongsTo('schedulevessel'),
    voyages: _emberData.default.hasMany('voyages'),
    portcalls: _emberData.default.attr(), // describing *all* portcalls shared by the carriers
    positions: _emberData.default.attr(), // describing *all* positions to draw a 'best track'

    sortedVoyages: Ember.computed.sort('voyages', 'sortKeys'),

    init: function init() {
      this.set('sortKeys', ['carrier']);
    },


    /**
     * Make list unique for carrier and service code.
     * @param {array} voyages - The raw yoyages array.
     */
    consolidatedVoyages: Ember.computed('voyages', 'vessel', function () {
      var newUniqueArray = [];
      var _this = this;
      this.get('voyages').forEach(function (voyage) {
        if (_this.alreadyThere(newUniqueArray, voyage.get('carrier'), voyage.get('serviceCode')) === false) {
          var newNode = {
            carrier: voyage.get('carrier'),
            scac: voyage.get('scac'),
            services: voyage.get('services'),
            voyages: [{
              voyageNumber: voyage.get('voyageNumber'),
              startDate: voyage.get('startDate'),
              endDate: voyage.get('endDate'),
              portcalls: voyage.get('portcalls'),
              positions: voyage.get('positions')
            }]
          };
          newUniqueArray.push(newNode);
        } else {

          var newVoyage = {
            voyageNumber: voyage.get('voyageNumber'),
            startDate: voyage.get('startDate'),
            endDate: voyage.get('endDate'),
            portcalls: voyage.get('portcalls'),
            positions: voyage.get('positions')
          };

          _this.addVoyage(newVoyage, newUniqueArray, voyage.get('carrier'), voyage.get('serviceCode'));
        }
      });
      return newUniqueArray;
    }),

    /**
     * Make list unique for carrier and service code.
     * @param {array} voyages - The raw yoyages array.
     */
    consolidatedAndSortedVoyages: Ember.computed('voyages', 'vessel', function () {
      var newUniqueArray = [];
      var _this = this;
      this.get('voyages').forEach(function (voyage) {
        if (_this.alreadyThere(newUniqueArray, voyage.get('carrier'), voyage.get('serviceCode')) === false) {
          var newNode = {
            carrier: voyage.get('carrier'),
            scac: voyage.get('scac'),
            services: voyage.get('services'),
            voyages: [{
              voyageNumber: voyage.get('voyageNumber'),
              startDate: voyage.get('startDate'),
              endDate: voyage.get('endDate'),
              portcalls: voyage.get('portcalls'),
              positions: voyage.get('positions')
            }]
          };
          newUniqueArray.push(newNode);
        } else {

          var newVoyage = {
            voyageNumber: voyage.get('voyageNumber'),
            startDate: voyage.get('startDate'),
            endDate: voyage.get('endDate'),
            portcalls: voyage.get('portcalls'),
            positions: voyage.get('positions')
          };
          _this.addVoyage(newVoyage, newUniqueArray, voyage.get('carrier'), voyage.get('serviceCode'));
        }
      });

      // we've got the list but do sort them by carrier as the VSN
      // works with voyages
      return newUniqueArray.sort(function (a, b) {
        var keyA = a.carrier,
            keyB = b.carrier;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    }),

    /**
     * Check if a specific carrier and service code combination already exists in array.
     * @param {array} aHaystack - The current array.
     * @param {string} sCarrier - The carrier name.
     * @param {string} sServiceCode - The service code.
     */

    alreadyThere: function alreadyThere(aHaystack, sCarrier, sServiceCode) {
      if (aHaystack.length === 0) {
        return false;
      }
      for (var i = 0; i < aHaystack.length; i++) {
        if (aHaystack[i].carrier === sCarrier && aHaystack[i].serviceCode === sServiceCode) {
          return true;
        }
      }
      return false;
    },


    /**
     * Append a voyage to the appr. carrier/service node's voyages array.
     * @param {object} oVoyage - The voyage to add (a dict/hash).
     * @param {array} aHaystack - The current array.
     * @param {string} sCarrier - The carrier name.
     * @param {string} sServiceCode - The service code.
     */
    addVoyage: function addVoyage(oVoyage, aHaystack, sCarrier, sServiceCode) {
      for (var i = 0; i < aHaystack.length; i++) {
        if (aHaystack[i].carrier === sCarrier && aHaystack[i].serviceCode === sServiceCode) {
          aHaystack[i].voyages.push(oVoyage);
        }
      }
    },


    allVoyagePorts: Ember.computed('portcalls', function () {
      var portList = [];
      this.get('portcalls').forEach(function (portcall) {
        portList.push(portcall.portid);
      });
      return portList;
    }),

    allVoyageCarriers: Ember.computed('consolidatedVoyages', function () {
      var allCarriers = this.get('store').peekAll('carrier');
      var carrierList = [];
      this.get('consolidatedVoyages').forEach(function (voyage) {
        carrierList.push(parseInt(allCarriers.filterBy('scac', voyage.scac)[0].id));
      });
      return carrierList;
    }),
    voyageEarliestDate: Ember.computed('consolidatedVoyages', function () {
      var voyageEarliestDate = undefined;
      this.get('consolidatedVoyages').forEach(function (voyage) {
        voyage.voyages.forEach(function (v) {
          v.portcalls.forEach(function (portcall) {
            if (portcall.etd < voyageEarliestDate || !voyageEarliestDate) {
              voyageEarliestDate = portcall.etd;
            }
          });
        });
      });
      return voyageEarliestDate;
    }),
    voyageLatestDate: Ember.computed('consolidatedVoyages', function () {
      var voyageLatestDate = undefined;
      this.get('consolidatedVoyages').forEach(function (voyage) {
        voyage.voyages.forEach(function (v) {
          v.portcalls.forEach(function (portcall) {
            if (Date(portcall.eta) > voyageLatestDate || !voyageLatestDate) {
              voyageLatestDate = portcall.eta;
            }
          });
        });
      });
      return voyageLatestDate;
    })
  });
});