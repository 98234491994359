define('css2/services/current-user', ['exports', 'css2/helpers/oi-change-customer-colors'], function (exports, _oiChangeCustomerColors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('usesIE11', /*@cc_on!@*/false || !!document.documentMode);
    },

    session: Ember.inject.service(),
    store: Ember.inject.service(),
    raven: Ember.inject.service(),

    usesIE11: false,

    load: function load() {
      var _this = this;

      var userAuthenticated = this.get('session.data.authenticated');
      if (!Ember.$.isEmptyObject(userAuthenticated)) {
        var promise = this.get('store').findAll('user').then(function (users) {
          _this.set('user', users.get('firstObject'));

          var _get$getProperties = _this.get('user').getProperties('username', 'email', 'fullname'),
              username = _get$getProperties.username,
              email = _get$getProperties.email,
              fullname = _get$getProperties.fullname;

          var domain = (email || '').split('@')[1];

          _paq.push(['setUserId', username + ' | ' + domain]);
          _this.get('raven').callRaven('setUserContext', { name: username, email: email });
          if (typeof zE !== 'undefined') {
            zE(function () {
              zE.identify({ name: fullname, email: email, source: 'CSW' });
            });
          }
          var particular = _this.get('user.customerparticular');
          if (particular.get('primaryColor') && particular.get('primaryColor')) {
            (0, _oiChangeCustomerColors.oiChangeCustomerStyle)(particular.get('primaryColor'), particular.get('secondaryColor'));
          }
        }).catch(function (error) {
          _this.get('raven').captureMessage('Trouble fetching user from ED store', {
            level: 'error',
            extra: { error: error }
          });
        });
        return promise;
      } else {
        return Ember.RSVP.resolve();
      }
    },


    firstAllowedRoute: Ember.computed('user.permission.{p2p,csn,vss,pis}', function () {
      var permission = this.get('user.permission');
      if (!permission) {
        return 'nopermissions';
      }

      var _permission$getProper = permission.getProperties('p2p', 'vss', 'csn', 'pis', 'bsr'),
          p2p = _permission$getProper.p2p,
          vss = _permission$getProper.vss,
          csn = _permission$getProper.csn,
          pis = _permission$getProper.pis,
          bsr = _permission$getProper.bsr;

      var permissionMap = {
        'p2psearchorder': p2p,
        'vesselschedule': vss,
        'csne': csn,
        'blanksailings': bsr,
        'portintelligence': pis
      };

      // transition to last route user was on - but only when he has permission for this route
      var lastKnowntransition = window.localStorage.getItem('currentTransition');
      if (lastKnowntransition) {
        if (permissionMap[lastKnowntransition.split('/')[1]]) {
          return lastKnowntransition;
        }
      }
      if (p2p) {
        return 'p2psearchorder';
      }
      if (vss) {
        return 'vesselschedule';
      }
      if (csn) {
        return 'csne';
      }
      if (bsr) {
        return 'blanksailings';
      }
      if (pis) {
        return 'portintelligence';
      }
      return 'nopermissions';
    }),

    isSsoManaged: Ember.computed(function () {
      var subDomain = window.location.host.split('.')[0];
      var domain_whitelist = ['css', 'css-test', 'betacss', 'css-na12', 'css-eu12'];
      return !(domain_whitelist.includes(subDomain) || subDomain.startsWith('localhost'));
    })

  });
});