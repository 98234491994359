define('css2/routes/vesselschedule/results', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {

    session: Ember.inject.service('session'),

    queryParams: {
      id: {
        refreshModel: true
      },
      start: {
        refreshModel: true
      },
      end: {
        refreshModel: true
      }
    },

    model: function model(params) {
      if (Ember.isNone(params.id)) {
        return [];
      }
      if (params.start) {
        this.set('saved_start', params.start);
      }
      if (params.end) {
        this.set('saved_end', params.end);
      }

      var vesselschedule = this.get('store').query('vesselschedule', params);
      return vesselschedule;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      // -----------------------------------------------------------------------------------------------------------------
      // cheap trick to keep the outer route's state when the call gets to the inner route's url
      // -----------------------------------------------------------------------------------------------------------------
      // poke out the vessel to from result, note that we do need to resolve promises...

      var _this = this;

      model.forEach(function (elem) {
        elem.get('vessel').then(function (vessel) {
          var shipname = vessel.get('shipname');
          var id = vessel.get('id');
          var imo = vessel.get('imo');
          var cap_teu = vessel.get('cap_teu');

          // set the vessel
          _this.controllerFor('vesselschedule').set('selectedVessel', {
            id: id,
            shipname: shipname,
            imo: imo,
            cap_teu: cap_teu
          });
          _this.controllerFor('vesselschedule').set('start', _this.get('saved_start'));
          _this.controllerFor('vesselschedule').set('end', _this.get('saved_end'));
        });
      });

      // set the date range with memorized request params :-)
      //this.controllerFor('vesselschedule').set('start', this.get('saved_start'));
      //this.controllerFor('vesselschedule').set('end', this.get('saved_end'));
    },
    resetController: function resetController(controller, isExiting /*, transition */) {
      if (isExiting) {
        controller.set('selectedVessel', null);
        controller.set('minDate', null);
        controller.set('maxDate', null);
        controller.set('currentSelectionIndex', 0);
      }
    }
  });
});