define('css2/components/oi-portcall-matrix-vessel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    schedule: null, // an array of Ember.Objects
    sortedVoyages: Ember.computed.sort('schedule.voyages', 'sortKeys'),
    tagName: 'div',
    classNames: ['oi-portcall-matrix'],

    init: function init() {
      this._super.apply(this, arguments);
      this.sortKeys = ['carrier'];
    },


    fromMapSelectedPortScacDidChange: Ember.observer('fromMapSelectedPortScac', function () {
      var _this = this;

      var portScacToBeSelected = this.get('fromMapSelectedPortScac');
      this.get('schedule.portcalls').forEach(function (portcall) {
        if (portcall.locode === portScacToBeSelected) {
          _this.set('selectedLocode', portcall.locode);
          _this.set('portIndex', undefined);
        }
      });
    }),

    actions: {
      clickServiceAtIndex: function clickServiceAtIndex(index) {
        this.sendAction('clickServiceAction', index);
      },
      selPort: function selPort(portid, index) {
        this.set('selectedLocode', undefined); // remove old selection
        this.sendAction('selPort', portid, index);
      }
    }
  });
});