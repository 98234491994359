define('css2/serializers/p2psearchorder', ['exports', 'ember-data', 'css2/serializers/drf'], function (exports, _emberData, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {

    attrs: { results: { embedded: 'always' } },
    primaryKey: 'handle',

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {

      var newPayload = Ember.$.extend(true, {}, payload);
      var payloadResults = payload.results;
      newPayload.results = [];

      var key = 0;

      var currentResult = null;
      var currentResultLegArray = null;

      for (var i = 0; i < payloadResults.length; i++) {
        currentResult = payloadResults[i];
        currentResultLegArray = currentResult.legs;
        var newLegArray = [];
        for (var j = 0; j < currentResultLegArray.length; j++) {
          var currentLeg = currentResultLegArray[j];
          currentLeg.id = key;
          newLegArray.push(currentLeg);
          key++;
        }
        currentResult.legs = newLegArray;
        newPayload.results.push(currentResult);
      }
      newPayload['id'] = 1;
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });
});