define('css2/authenticators/jwt', ['exports', 'ember-simple-auth/authenticators/base', 'css2/config/environment', 'fetch'], function (exports, _base, _environment, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    applicationService: Ember.inject.service(),
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    raven: Ember.inject.service('raven'),

    invalidateToken: function invalidateToken(url) {
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.$.ajax({
          url: url,
          crossDomain: true,
          xhrFields: { withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS },
          type: 'POST',
          statusCode: {
            205: function _(xhr) {
              resolve(xhr);
            }
          }
        });
      });
    },
    makeRequest: function makeRequest(url, data) {
      var headers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var host = window.location.host;

      var authenticator = this;
      var options = {
        headers: headers,
        method: 'GET',
        credentials: 'include'
      };
      return new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _fetch.default)(url, options).then(function (response) {
          response.text().then(function (result) {
            if (response.status === 200) {
              var jsonResult = JSON.parse(result);
              if (jsonResult.valid_tokens.length > 0) {
                var token = jsonResult.valid_tokens[0];
                var principalUrl = _environment.default.APP.P44_PRINCIPAL_URLS[host + '_' + token];
                (0, _fetch.default)(principalUrl, options).then(function (principalResponse) {
                  if (principalResponse.status === 200) {
                    authenticator.segmentIdentify(principalResponse.json());
                  }
                });
              }
              resolve(jsonResult);
            } else {
              reject(result);
            }
          });
        }).catch(reject);
      });
    },
    checkToken: function checkToken() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var url = _this.get('applicationService.apiHost') + '/p44/token-check/';
        _this.makeRequest(url).then(function (response) {
          Ember.run(null, resolve, response);
        }, function (response) {
          Ember.run(null, reject, response);
        });
      });
    },
    authenticate: function authenticate() {
      return this.checkToken();
    },
    restore: function restore() {
      return this.checkToken();
    },
    invalidate: function invalidate() {
      return this.invalidateToken(this.get('applicationService.apiHost') + '/p44/logout/');
    },
    segmentIdentify: function segmentIdentify(authData) {
      var _window = window,
          analytics = _window.analytics;

      if (analytics) {
        analytics.identify(authData.username, {
          userId: authData.username,
          email: authData.username,
          tenantId: authData.tenantId,
          tenantName: authData.companyName,
          networkRole: authData.tenantCarrierNetworkRoles[0],
          displayName: authData.firstName + ' ' + authData.lastName
        });
      }
    }
  });
});