define('css2/controllers/home', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service('current-user'),

    status: Ember.computed('currentUser.user', function () {
      if (this.get('currentUser.user')) {
        Ember.run.next(this, function () {
          this.transitionToRoute(this.get('currentUser.firstAllowedRoute')); // some sort of try again
        });
      }
    })
  });
});