define('css2/helpers/oi-change-customer-colors', ['exports', 'css2/helpers/lighten-darken-hex'], function (exports, _lightenDarkenHex) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.oiChangeCustomerStyle = oiChangeCustomerStyle;


  function hexToRgb(hex) {
    var opacity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

    var hexStripped = hex.replace('#', '');
    var r = parseInt(hexStripped.substring(0, 2), 16);
    var g = parseInt(hexStripped.substring(2, 4), 16);
    var b = parseInt(hexStripped.substring(4, 6), 16);

    var result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';

    return result;
  }

  function oiChangeCustomerStyle(primaryColor, secondaryColor) {
    var root = document.documentElement;
    root.style.setProperty('--primary-color', hexToRgb(primaryColor));
    root.style.setProperty('--primary-color-dark', (0, _lightenDarkenHex.lightenDarkenHex)(primaryColor, -10));
    root.style.setProperty('--primary-color-light', (0, _lightenDarkenHex.lightenDarkenHex)(primaryColor, 20, 0.8, 205));
    root.style.setProperty('--primary-color-extra-light', (0, _lightenDarkenHex.lightenDarkenHex)(primaryColor, 90, 0.15, 255));
    root.style.setProperty('--secondary-color', hexToRgb(secondaryColor));
  }

  exports.default = Ember.Helper.helper(oiChangeCustomerStyle);
});