define('css2/models/schedulevessel', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    imo: _emberData.default.attr('number'),
    shipname: _emberData.default.attr('string'),
    flag: _emberData.default.attr(),
    buildYear: _emberData.default.attr('number'),
    deadweight: _emberData.default.attr('number'),
    capTeu: _emberData.default.attr('number'),
    capTeu14: _emberData.default.attr('number'),
    capTeuReefer: _emberData.default.attr('number'),
    length: _emberData.default.attr('number'),
    width: _emberData.default.attr('number')
  });
});