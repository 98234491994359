define('css2/routes/csne/results', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    queryParams: {
      id: {
        refreshModel: true
      }
    },

    model: function model(params) {
      if (Ember.isPresent(params.id)) {
        return this.get('store').findRecord('servicenetwork', params.id);
      }
      return null;
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      if (Ember.isNone(model)) {
        return;
      }

      // this is all about restoring the two drop-dwons from the csne route
      // carrier
      var requestedCarrierId = model.get('carrierId');
      var csne = this.controllerFor('csne');
      var carrierList = csne.get('model');

      carrierList.forEach(function (element) {
        if (parseInt(element.id) === parseInt(requestedCarrierId)) {
          // parseInt as in "we never know"
          _this.controllerFor('csne').set('selectedCarrier', element);
        }
      });

      // service
      var serviceId = model.get('id');
      var serviceName = model.get('name');
      this.controllerFor('csne').set('selectedService', {
        id: serviceId,
        title: serviceName
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('portId', -99);
        controller.set('selectedCarrier', null);
        controller.set('selectedService', null);
        controller.set('currentSelectionIndex', 0);
      }
    }
  });
});