define('css2/components/oi-multiselect-checkboxes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MultiSelectCheckbox = Ember.Object.extend({
    label: null,
    value: null,
    isChecked: false,
    changeValue: function changeValue() {
      // do nothing.
    },
    onIsCheckedChanged: Ember.observer('isChecked', function () {

      var fn = this.get('isChecked') === true ? 'pushObject' : 'removeObject';
      this.get('changeValue').call(this, fn, this.get('value'));
    })

  });

  exports.default = Ember.Component.extend({

    options: null,
    selected: null,

    checkboxes: Ember.computed('options', 'selected', function () {
      var _this = this;
      var labelProperty = this.get('labelProperty');
      var valueProperty = this.get('valueProperty');
      var selected = this.get('selected');
      var onchange = this.get('onchange');
      return this.get('options').map(function (opt) {
        var label = opt[labelProperty] || opt.get(labelProperty);

        // use condition here to prevent crashing app when list of objects is passed
        var value = opt[valueProperty];
        if (value === undefined) {
          value = opt.get(valueProperty);
        }
        var isChecked = _this.containsObject(value, selected, valueProperty);
        return MultiSelectCheckbox.create({
          label: label,
          value: value,
          isChecked: isChecked,
          onchange: onchange,
          changeValue: function changeValue(fn, value) {
            _this.get('onchange')(fn, value);
          }
        });
      });
    }),

    containsObject: function containsObject(str, list, field) {
      var i = void 0;
      for (i = 0; i < list.length; i++) {
        var current = list[i][field];

        // use condition here to prevent crashing app when list of objects is passed
        if (current === undefined) {
          current = list[i].get(field);
        }
        if (current === str) {
          return true;
        }
      }
      return false;
    }
  });
});