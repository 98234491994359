define('css2/models/servicenetwork', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    carrierId: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'), // service name of the request!
    title: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    tradelane: _emberData.default.attr('string'),
    direction: _emberData.default.attr('string'),
    frequency: _emberData.default.attr('string'),
    alliance: _emberData.default.attr('string'),
    vessel: _emberData.default.attr('number'),
    vessels: _emberData.default.hasMany('vessel'),
    teu: _emberData.default.attr('number'),
    portcalls: _emberData.default.attr(),
    positions: _emberData.default.attr(),
    rotations: _emberData.default.attr(),

    requestcarrier: Ember.computed('title', function () {
      return { name: 'Carriername' };
    }),
    requestservice: Ember.computed('title', function () {
      return { name: 'Servicename' };
    })

  });
});