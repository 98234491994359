define('css2/components/oi-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['oi-list'],
    sortDefinition: null,
    earliestDeparture: null,
    latestArrival: null,
    timespan: 0,
    value: null,
    queryStart: null,
    queryEnd: null,
    currentUser: Ember.inject.service('current-user'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('sortDefinition', {});
    },


    actions: {
      resetAll: function resetAll() {
        Ember.get(this, 'actionName')();
      }
    },

    lowerLimit: Ember.computed('value', function () {
      var v = this.get('value');
      return v[0];
    }),
    upperLimit: Ember.computed('value', function () {
      var v = this.get('value');
      return v[1];
    })

  });
});