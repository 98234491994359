define('css2/serializers/blanksailings/report', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    extractId: function extractId() {
      return 'latest';
    }
  });
});