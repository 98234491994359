define('css2/models/voyage', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    carrier: _emberData.default.attr('string'),
    scac: _emberData.default.attr('string'),
    services: _emberData.default.attr(),
    portcalls: _emberData.default.attr(),
    positions: _emberData.default.attr()
  });
});