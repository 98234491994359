define('css2/controllers/portintelligence', ['exports', 'css2/constants/general', 'moment'], function (exports, _general, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var WEEK_NUMBER_OPTIONS = [{ title: 'next 2 weeks', value: 2 }, { title: 'next 4 weeks', value: 4 }, { title: 'next 6 weeks', value: 6 }];

  exports.default = Ember.Controller.extend({
    resultsController: Ember.inject.controller('portintelligence.results'),
    selectedPort: null,
    selectedDate: null,
    currentWeekOption: WEEK_NUMBER_OPTIONS[0],
    weekNumberOptions: WEEK_NUMBER_OPTIONS,
    session: Ember.inject.service('session'),
    weeks: Ember.computed('currentWeekOption.value', {
      get: function get() {
        return this.currentWeekOption.value;
      },
      set: function set(key, value) {
        this.set('currentWeekOption', WEEK_NUMBER_OPTIONS.find(function (wno) {
          return wno.value === value;
        }));
      }
    }),
    actions: {
      searchPort: function searchPort(term) {
        return this.store.query('port', { s: term });
      },
      etaStartDate: function etaStartDate(arg) {
        this.set('startdate', (0, _moment.default)(arg).format('YYYY-MM-DD'));
      },
      requestSearch: function requestSearch() {
        var port = this.get('selectedPort');
        var date = this.get('currentWeekOption');
        switch (date.value) {
          case 2:
            this.set('resultsController.legendColors', _general.VESSEL_ETA_COLORS_2_WEEKS);break;
          case 4:
            this.set('resultsController.legendColors', _general.VESSEL_ETA_COLORS_4_WEEKS);break;
          case 6:
            this.set('resultsController.legendColors', _general.VESSEL_ETA_COLORS_6_WEEKS);break;
          default:
            this.set('resultsController.legendColors', _general.VESSEL_ETA_COLORS_2_WEEKS);
        }

        if (Ember.isPresent(port)) {
          this.transitionToRoute('portintelligence.results', {
            queryParams: {
              startdate: this.get('startdate'),
              port: port.id || port.content.id,
              weeks: date.value
            }
          });
        }
      }
    }
  });
});