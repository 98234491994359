define('css2/serializers/user', ['exports', 'ember-data', 'css2/serializers/drf'], function (exports, _emberData, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    primaryKey: 'id',
    attrs: {
      permission: { embedded: 'always' },
      customerparticular: { embedded: 'always' }
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload.id = 1;

      var permHash = payload.permissions;
      permHash.id = 1;
      payload['permission'] = permHash;

      var partHash = payload.customer_particular;
      if (partHash) {
        partHash.id = 1;
        payload.customerparticular = partHash;
      }

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});