define('css2/authenticators/sso', ['exports', 'ember-simple-auth/authenticators/oauth2-implicit-grant'], function (exports, _oauth2ImplicitGrant) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2ImplicitGrant.default.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    raven: Ember.inject.service('raven'),

    init: function init() {
      this._super();
    },
    authenticate: function authenticate(response) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        Ember.run(function () {
          _this.setToken(response);

          resolve(response).then(function () {
            return _this.get('currentUser').load().catch(function () {
              return _this.get('session').invalidate();
            });
          });
        });
      });
    },


    setToken: function setToken(ssoCredentials) {
      this.get('session').set('data.authenticated.access_token', ssoCredentials.access_token);
      this.get('session').set('isAuthenticated', true);
    }
  });
});