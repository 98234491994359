define('css2/custom/pollster', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    /*
    interval: function () {
      return 5000; // Time between polls (in ms)
    }.property().readOnly(),
    */

    interval: Ember.computed(function () {
      return 5000;
    }),

    init: function init() {
      this._super.apply(this, arguments);
    },


    // Schedules the function `f` to be executed every `interval` time.
    schedule: function schedule(f) {
      return Ember.run.later(this, function () {
        f.apply(this);
        if (this.get('_dowork')) {
          // only schedule the next cycle if the flag is true
          this.set('timer', this.schedule(f));
        }
      }, this.get('interval'));
    },

    // Stops the pollster
    stop: function stop() {
      this.set('_dowork', false); // instead of canceling (which does not seem to work), we use a flag
      Ember.run.cancel(this.get('timer'));
    },

    // Starts the pollster, i.e. executes the `onPoll` function every interval.
    start: function start() {
      this.onPoll(); // make the first call by hand to avoid 10000 ms delay!
      this.set('_dowork', true);
      this.set('timer', this.schedule(this.get('onPoll')));
    },

    // will be overwritten when creating an instance!
    onPoll: function onPoll() {
      // Issue JSON request and add data to the store
    }
  });
});