define('css2/models/p2pvoyage', ['exports', 'ember-data', 'ember-data/relationships', 'moment'], function (exports, _emberData, _relationships, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    carrier: _emberData.default.belongsTo('carrier'),
    pol: (0, _relationships.belongsTo)('pol'),
    pod: (0, _relationships.belongsTo)('pod'),
    etd: _emberData.default.attr('momentdate'), //departure
    eta: _emberData.default.attr('momentdate'), //arrival
    etdLocal: _emberData.default.attr('string'), //departure
    etaLocal: _emberData.default.attr('string'), //arrival
    transshipmentCount: _emberData.default.attr('number'),
    transitTime: _emberData.default.attr('number'),
    updated: _emberData.default.attr('date'),
    legs: (0, _relationships.hasMany)('leg'),
    //alliance: DS.attr(),
    alliance: (0, _relationships.belongsTo)('alliance'),
    serviceCodeEnum: Ember.computed('legs', function () {
      var legsArray = this.get('legs');
      var returnString = '';
      legsArray.toArray().forEach(function (item) {
        returnString += item.get('serviceCode') + ', ';
      });
      return returnString.substr(0, returnString.length - 2);
    }),
    via: Ember.computed('legs', 'transshipmentCount', function () {
      var legsArray = this.get('legs');
      var tsCount = this.get('transshipmentCount');
      var returnString = '';
      //var i = 0;
      for (var i = 0; i < legsArray.toArray().length; i++) {
        var leg = legsArray.toArray()[i];
        if (i < tsCount) returnString += leg.get('pod.name') + '/ ';
      }
      return returnString.substr(0, returnString.length - 2);
    }),

    serviceCodeEnumStyled: Ember.computed('legs', function () {
      var legsArray = this.get('legs');
      var returnString = '';
      var maxi = 0;
      legsArray.toArray().forEach(function (item) {
        var tt = item.get('transitTime');
        if (Math.max(tt) > maxi) {
          maxi = tt;
        }
      });

      legsArray.toArray().forEach(function (item) {
        if (item.get('transitTime') === maxi) {
          returnString += '<b>' + item.get('serviceCode') + '</b>, ';
        } else {
          returnString += item.get('serviceCode') + ', ';
        }
      });
      return Ember.String.htmlSafe(returnString.substr(0, returnString.length - 2));
    }),

    pureEtd: Ember.computed('etd', function () {
      return _moment.default.utc(this.get('etd')).format('YYYY-MM-DD');
    }),

    pureEta: Ember.computed('eta', function () {
      return _moment.default.utc(this.get('eta')).format('YYYY-MM-DD');
    }),

    pureEtdLocal: Ember.computed('etdLocal', function () {
      return _moment.default.parseZone(this.get('etdLocal')).format('YYYY-MM-DD HH:mm');
    }),

    pureEtaLocal: Ember.computed('etaLocal', function () {
      return _moment.default.parseZone(this.get('etaLocal')).format('YYYY-MM-DD HH:mm');
    }),

    pureEtdLocalDate: Ember.computed('etdLocal', function () {
      return _moment.default.parseZone(this.get('etdLocal')).format('YYYY-MM-DD');
    }),

    pureEtaLocalDate: Ember.computed('etaLocal', function () {
      return _moment.default.parseZone(this.get('etaLocal')).format('YYYY-MM-DD');
    }),
    incidents: Ember.computed('incident', function () {
      var _this = this;

      return this.store.peekAll('incident').filter(function (incident) {
        return _this.incidentIsMatching(incident);
      });
    }),
    incidentIsMatching: function incidentIsMatching(incident) {
      if (!incident.dateMatch(this.get('etd'), this.get('eta'))) {
        return false;
      }
      if (incident.get('carriers').length > 0) {
        if (!incident.carrierMatch(this.get('carrier.id'))) {
          return false;
        }
      }
      if (incident.get('vessels').length > 0) {
        if (!this.get('legs').find(function (leg) {
          return incident.vesselMatch(leg.get('vessel.id'));
        })) {
          return false;
        }
      }
      if (incident.get('ports').length > 0) {
        if (!(incident.portsMatch(this.get('pol.id')) || incident.portsMatch(this.get('pod.id')) || this.get('legs').find(function (leg) {
          return incident.portsMatch(leg.get('pod.id'));
        }))) {
          return false;
        }
      }
      return true;
    }
  });
});