define('css2/models/portintelligence', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    imo: _emberData.default.attr('number'),
    shipname: _emberData.default.attr('string'),
    cap_teu: _emberData.default.attr('number'),
    eta: _emberData.default.attr('string'),
    timezone: _emberData.default.attr('string'),
    position: _emberData.default.attr()
  });
});