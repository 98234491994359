define('css2/components/oi-portcall-matrix', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['oi-portcall-matrix'],

    fromMapSelectedPortScacDidChange: Ember.observer('fromMapSelectedPortScac', function () {
      var _this = this;

      var portScacToBeSelected = this.get('fromMapSelectedPortScac');
      this.get('schedule.portcalls').forEach(function (portcall, index) {
        var locode = portcall.locode || portcall.port.locode;
        if (locode === portScacToBeSelected) {
          _this.set('portIndex', index);
        }
      });
    }),

    actions: {
      clickServiceAtIndex: function clickServiceAtIndex(index) {
        //get(this, 'clickServiceAction')(index);
        this.sendAction('clickServiceAction', index);
      },
      selPort: function selPort(portid, index) {
        this.sendAction('selPort', portid, index);
      }
    }
  });
});