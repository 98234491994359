define('css2/routes/nopermissions', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUser: Ember.inject.service('current-user'),

    beforeModel: function beforeModel() {
      if (this.get('currentUser.firstAllowedRoute') !== 'nopermissions') {
        return this.transitionTo(this.get('currentUser.firstAllowedRoute'));
      }
      return this._super.apply(this, arguments);
    }
  });
});