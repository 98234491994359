define('css2/transforms/momentdate', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    /************************************************************************************************
     * We usually get three different date representation from API (or from the DRFAdapter):
     *
     * - null
     * - 2016-01-01 and
     * - 2015-11-21T18:16+0000
     *
     * Moment.js is being used to convert the third one to a string that can be parsed
     * safely by all browsers. And as moment.js does not like to be initialized with null
     * we skip those values.
     ***************************************************************************************/
    deserialize: function deserialize(serialized) {
      if (serialized !== null) {
        /*
        2016-07-28T07:19+0000
        2015-10-31T11:06+0000
         */
        var isoDateString = (0, _moment.default)(serialized).toISOString();
        //var isoDateString = moment.utc(serialized).format("YYYY-MM-DD HH:mm");
        return serialized.length === 10 ? serialized : isoDateString;
      }
      // null remains null
      return serialized;
    },
    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });
});