define('css2/helpers/get-date-difference', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getDateDifference = getDateDifference;
  function getDateDifference(date1, date2) {
    return date1.diff(date2, 'days');
  }

  exports.default = Ember.Helper.helper(getDateDifference);
});