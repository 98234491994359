define('css2/components/oi-generic-map', ['exports', 'css2/config/environment', 'moment', 'css2/helpers/get-date-difference'], function (exports, _environment, _moment, _getDateDifference) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var PORT_IMAGE = '/images/icon_blank.png';

  exports.default = Ember.Component.extend({
    map: null,
    ports: null,
    vessels: null,

    drawPortMarkers: function drawPortMarkers() {
      var _this2 = this;

      this.map.loadImage(PORT_IMAGE, function (error, image) {
        _this2.map.addImage(PORT_IMAGE, image);
      });

      var features = this.get('ports').map(function (port) {
        return {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'Point',
            'coordinates': [port.get('longitude'), port.get('latitude')]
          }
        };
      });

      this.map.addSource('points', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': features
        }
      });

      this.map.addLayer({
        'id': 'points',
        'type': 'symbol',
        'source': 'points',
        layout: {
          'icon-allow-overlap': true,
          'icon-size': 1,
          'icon-anchor': 'bottom',
          'icon-offset': [0, -3],
          'icon-image': PORT_IMAGE
        }
      });
    },
    drawVesselMarkers: function drawVesselMarkers() {
      var _this3 = this;

      var properties = void 0;
      var weeks = this.get('weeks');
      var startTime = this.get('vessels').query.startdate;
      if (startTime === undefined) {
        startTime = (0, _moment.default)();
      } else {
        startTime = (0, _moment.default)(startTime, 'YYYY-MM-DD');
      }
      var features = this.get('vessels').map(function (vessel) {
        properties = _this3.get('colorizedVesselMarkers') ? {
          'vessel_color': weeks == '2' ? String(Math.round((0, _getDateDifference.getDateDifference)(vessel.get('eta'), startTime) / 2)) : weeks == '4' ? String(Math.round((0, _getDateDifference.getDateDifference)(vessel.get('eta'), startTime) / 2)) : String(Math.round((0, _getDateDifference.getDateDifference)(vessel.get('eta'), startTime) / 4))
        } : {};
        return {
          'type': 'Feature',
          'properties': properties,
          'geometry': {
            'type': 'Point',
            'coordinates': [vessel.get('position.longitude'), vessel.get('position.latitude')]
          }
        };
      });

      this.map.addSource('vessels', {
        'type': 'geojson',
        'data': {
          'type': 'FeatureCollection',
          'features': features
        }
      });

      var circleColor = this.get('colorizedVesselMarkers') ? ['match', ['get', 'vessel_color']] : '#00558b';

      var colours = this.get('legendColors');
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.entries(colours)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var _ref = _step.value;

          var _ref2 = _slicedToArray(_ref, 2);

          var key = _ref2[0];
          var value = _ref2[1];

          circleColor.push(key);
          var c = JSON.parse(JSON.stringify(value));
          circleColor.push(c.vessel_color);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      circleColor.push('#ccc');

      this.map.addLayer({
        'id': 'circle',
        'type': 'circle',
        'source': 'vessels',
        'paint': {
          'circle-color': circleColor,
          'circle-radius': 5,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#222'
        }
      });
    },


    bounds: Ember.computed('portMarkers', function () {
      var bounds = new mapboxgl.LngLatBounds();

      this.get('ports').forEach(function (item) {
        bounds.extend([item.get('longitude'), item.get('latitude')]);
      });

      return [/* eslint-disable no-underscore-dangle */ // mapboxgl's name choice, not ours
      [bounds._ne.lng + 15, bounds._ne.lat + 15], [bounds._sw.lng - 15, bounds._sw.lat - 15]]; /* eslint-enable no-underscore-dangle */
    }),

    isChineseClient: function isChineseClient() {
      return navigator.languages.some(function (language) {
        return language.includes('zh');
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments); // eslint-disable-line prefer-rest-params

      var style = this.isChineseClient() ? 'mapbox://styles/oceaninsights/cl4sevc9f000l16qmuwcq2us1?optimize=true' : 'mapbox://styles/oceaninsights/cim0pob6300jaccm3a0e5wb3s';

      mapboxgl.accessToken = _environment.default['mapbox-gl'].accessToken;
      var map = void 0;
      try {
        map = new mapboxgl.Map({
          container: this.divId,
          style: style,
          center: [0, 0],
          zoom: 2,
          maxZoom: 12,
          minZoom: 1
        });
      } catch (error) {
        Ember.$('#map').css('height', '20px');
        var additionalInformation = '<a target="_blank" href="https://wevideo.zendesk.com/hc/en-us/articles/225259448-How-to-enable-WebGL">' + ' See this site <i class="fa fa-external-link"></i></a> on how to enable WebGL in your browser if it is not already.';

        this.set('mapboxError', Ember.String.htmlSafe('Sorry, you need a browser that supports WebGL to view the map.           (' + error + '). <br> ' + additionalInformation));
        throw error;
      }
      /* eslint no-underscore-dangle: 0 */
      var _this = this;
      map.on('load', function () {
        _this.mapLoaded(this);
      });
    },
    mapLoaded: function mapLoaded(e) {
      if (this.isDestroyed) {
        // user went elsewhere in the meantime (other tab, other route)...
        return; // ...so at least prevent console error "calling set on destroyed object"
      }

      this.set('map', e);
      e.addControl(new mapboxgl.NavigationControl({ showCompass: false }));
      e.dragRotate.disable();

      this.drawMap();
      this.fitBounds();
    },
    drawMap: function drawMap() {
      this.drawVesselMarkers();
      this.drawPortMarkers();
    },
    fitBounds: function fitBounds() {
      this.map.fitBounds(this.get('bounds'));
    }
  });
});