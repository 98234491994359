define('css2/components/oi-models-table/oi-chart', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Component.extend({
        chartOptions: Ember.computed('data.services', function () {
            var _this = this;

            var hasPermission = this.get('hasPermission');
            var services = this.get('serviceData');
            return {
                colors: ['#E12D39', '#CBD2D9', '#DE911D'],
                legend: {
                    enabled: false
                },
                chart: {
                    type: this.get('type'),
                    backgroundColor: '#F5F7FA',
                    height: 250,
                    spacingLeft: 16,
                    spacingRight: 16,
                    style: {
                        fontFamily: 'Inter',
                        color: '#9AA5B1'
                    }
                },
                lang: {
                    noData: hasPermission ? 'No data to display' : 'You do not have the permission to see this chart'
                },
                title: {
                    align: 'left',
                    text: this.get('title')
                },
                xAxis: {
                    lineColor: '#9AA5B1',
                    tickColor: '#9AA5B1',
                    categories: hasPermission ? [].concat(_toConsumableArray(new Set(services.map(function (item) {
                        return item[_this.get('groupBy')];
                    })))) : null,
                    labels: {
                        style: {
                            color: '#9AA5B1'
                        }
                    },
                    min: this.get('min'),
                    max: this.get('max'),
                    scrollbar: {
                        enabled: this.get('scroll')
                    }
                },
                yAxis: {
                    lineColor: '#9AA5B1',
                    tickColor: '#9AA5B1',
                    title: {
                        enabled: false,
                        text: 'Affected Services'
                    },
                    labels: {
                        style: {
                            color: '#9AA5B1'
                        }
                    }
                }
            };
        }),

        serviceData: Ember.computed('data.services', function () {
            var services = this.get('data.services');
            if (this.get('groupBy') === 'weeks') {
                var weeks = this.get('data.weeks');
                var serviceDataByWeeks = [];
                services.forEach(function (item) {
                    if (item.status) {
                        Object.keys(item.status).forEach(function (key) {
                            var status = {};
                            status[key] = item.status[key];
                            serviceDataByWeeks.push({
                                weeks: weeks[key],
                                status: status
                            });
                        });
                    }
                });
                services = serviceDataByWeeks;
            }
            return services;
        }),

        chartData: Ember.computed('data.services', function () {
            if (!this.get('hasPermission')) {
                return [];
            }

            var services = this.get('serviceData');
            var groupBy = this.get('groupBy');
            var categories = [].concat(_toConsumableArray(new Set(services.map(function (item) {
                return item[groupBy];
            }))));
            var blankedSailings = [];
            var scheduledSailings = [];
            var toBeConfirmed = [];

            categories.forEach(function (category) {
                var items = services.filter(function (item) {
                    return item[groupBy] === category;
                });
                var result = items.reduce(function (result, item) {
                    if (item.status) {
                        var statusValues = Object.values(item.status);
                        var _blankedSailings = statusValues.filter(function (item) {
                            return item === 0;
                        });
                        var _scheduledSailings = statusValues.filter(function (item) {
                            return item === 1;
                        });
                        var _toBeConfirmed = statusValues.filter(function (item) {
                            return item === 2;
                        });

                        result.blanked = result.blanked + _blankedSailings.length;
                        result.scheduled = result.scheduled + _scheduledSailings.length;
                        result.toBeConfirmed = result.toBeConfirmed + _toBeConfirmed.length;
                    }

                    return result;
                }, { blanked: 0, scheduled: 0, toBeConfirmed: 0 });

                blankedSailings.push(result.blanked);
                scheduledSailings.push(result.scheduled);
                toBeConfirmed.push(result.toBeConfirmed);
            });

            return [{
                name: 'Blanked Sailings',
                data: blankedSailings
            }, {
                name: 'Scheduled Sailings',
                data: scheduledSailings
            }, {
                name: 'No Sailings detected',
                data: toBeConfirmed
            }];
        })
    });
});