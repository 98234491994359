define('css2/services/application-service', ['exports', 'css2/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    apiHost: Ember.computed(function () {
      var host = _environment.default.APP.UI_API_HOSTS[window.location.hostname];
      if (host) {
        return host;
      }
      return _environment.default.APP.API_HOST;
    }),

    apiBase: Ember.computed('apiHost', function () {
      return this.get('apiHost') + '/' + _environment.default.APP.API_NAMESPACE;
    }),

    linkToVoc: Ember.computed(function () {
      return _environment.default.APP.P44_LINKS_TO_VOC[window.location.hostname];
    }),

    redirectLink: Ember.computed(function () {
      var redirectLink = _environment.default.APP.P44_REDIRECT_BACK_TO[window.location.host];
      if (redirectLink) {
        return redirectLink;
      }
      return window.location.protocol + '//' + window.location.host + '/';
    })
  });
});