define('css2/components/oi-field-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    result: Ember.computed('index', 'portcall', 'voyages', function () {

      var index = this.get('index');
      var voyages = this.get('voyages');
      var portcallUuid = this.get('portcall');

      var selectedVoyage = voyages[index] || { portcalls: [] }; // only to please test... :-|
      var selectedPortcalls = selectedVoyage.portcalls;

      for (var i = 0; i < selectedPortcalls.length; i++) {
        var portcall = selectedPortcalls[i];
        if (portcall.uuid === portcallUuid) {
          var time = portcall.transit_time;
          var departure = portcall.departure || 'n/a';
          return time + ' / ' + departure;
        }
      }
      return '--';
    })
  });
});