define('css2/models/blanksailings/report', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    createdAt: _emberData.default.attr('momentdate'),
    weeks: _emberData.default.attr(),
    services: _emberData.default.attr(),

    formattedDate: Ember.computed('createdAt', function () {
      return _moment.default.utc(this.get('createdAt')).format('YYYY-MM-DD HH:mm');
    })
  });
});