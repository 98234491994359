define('css2/constants/general', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var VESSEL_ETA_COLORS_2_WEEKS = exports.VESSEL_ETA_COLORS_2_WEEKS = {
    0: { 'vessel_color': '#FF0000', 'legend': '0-1' }, 1: { 'vessel_color': '#FF8F00', 'legend': '2-3' },
    2: { 'vessel_color': '#F3FF00', 'legend': '3-4' }, 3: { 'vessel_color': '#C9FF00', 'legend': '5-6' },
    4: { 'vessel_color': '#00FF4D', 'legend': '7-8' }, 5: { 'vessel_color': '#00ECFF', 'legend': '9-10' },
    6: { 'vessel_color': '#005DFF', 'legend': '11-12' }, 7: { 'vessel_color': '#002E7F', 'legend': '13-14' }
  };

  var VESSEL_ETA_COLORS_4_WEEKS = exports.VESSEL_ETA_COLORS_4_WEEKS = {
    0: { 'vessel_color': '#FF0000', 'legend': '0-1' }, 1: { 'vessel_color': '#FF0000', 'legend': '2-3' },
    2: { 'vessel_color': '#FF8F00', 'legend': '4-5' }, 3: { 'vessel_color': '#FF8F00', 'legend': '6-7' },
    4: { 'vessel_color': '#F3FF00', 'legend': '8-9' }, 5: { 'vessel_color': '#F3FF00', 'legend': '10-11' },
    6: { 'vessel_color': '#C9FF00', 'legend': '12-13' }, 7: { 'vessel_color': '#C9FF00', 'legend': '14-15' },
    8: { 'vessel_color': '#00FF4D', 'legend': '16-17' }, 9: { 'vessel_color': '#00FF4D', 'legend': '18-19' },
    10: { 'vessel_color': '#00ECFF', 'legend': '20-21' }, 11: { 'vessel_color': '#00ECFF', 'legend': '22-23' },
    12: { 'vessel_color': '#005DFF', 'legend': '24-25' }, 13: { 'vessel_color': '#005DFF', 'legend': '26-27' }
  };

  var VESSEL_ETA_COLORS_6_WEEKS = exports.VESSEL_ETA_COLORS_6_WEEKS = {
    0: { 'vessel_color': '#FF0000', 'legend': '0-3' }, 1: { 'vessel_color': '#FF0000', 'legend': '4-7' },
    2: { 'vessel_color': '#FF8F00', 'legend': '8-11' }, 3: { 'vessel_color': '#FF8F00', 'legend': '12-15' },
    4: { 'vessel_color': '#F3FF00', 'legend': '16-19' }, 5: { 'vessel_color': '#F3FF00', 'legend': '20-23' },
    6: { 'vessel_color': '#C9FF00', 'legend': '24-27' }, 7: { 'vessel_color': '#C9FF00', 'legend': '28-31' },
    8: { 'vessel_color': '#00FF4D', 'legend': '32-35' }, 9: { 'vessel_color': '#00FF4D', 'legend': '36-39' },
    10: { 'vessel_color': '#00ECFF', 'legend': '40-43' }, 11: { 'vessel_color': '#00ECFF', 'legend': '44-47' },
    12: { 'vessel_color': '#005DFF', 'legend': '48-51' }, 13: { 'vessel_color': '#005DFF', 'legend': '52-55' }
  };
});