define('css2/components/oi-field-picker-vessel', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    result: Ember.computed('index', 'portcall', 'voyages', function () {

      var index = this.get('index');
      var voyages = this.get('voyages');
      var portcallUuid = this.get('portcall');
      var selectedVoyage = voyages[index] || { 'portcalls': [] };
      var selectedPortcalls = null;

      // voyage can be [], see tests!
      try {
        selectedPortcalls = selectedVoyage.get('portcalls');
      } catch (error) {
        selectedPortcalls = selectedVoyage.portcalls;
      }

      for (var i = 0; i < selectedPortcalls.length; i++) {
        var portcall = selectedPortcalls[i];
        if (portcall.uuid === portcallUuid) {
          var etaHtml = '<span class="oi-arrival">A</span>' + _moment.default.utc(portcall.eta).format('MMM DD') + '<br>';
          var etdHtml = '<span class="oi-departure">D</span>' + _moment.default.utc(portcall.etd).format('MMM DD') + '<br>';
          //var servicesHtml = this.concatenate(selectedVoyage.get('services'));
          return Ember.String.htmlSafe(etaHtml + etdHtml /* + servicesHtml */);
        }
      }
      return '--';
    }),

    concatenate: function concatenate(arg) {
      var sum = '';
      arg.forEach(function (elem) {
        sum += elem.code + ', ';
      });
      return sum.substr(0, sum.length - 2);
    }
  });
});