define('css2/serializers/voyage', ['exports', 'ember-data', 'css2/serializers/drf'], function (exports, _emberData, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    primaryKey: 'scac',
    attrs: {
      portcalls: { embedded: 'always' },
      services: { embedded: 'always' },
      positions: { embedded: 'always' }
    }

  });
});