define('css2/routes/login', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'fetch'], function (exports, _unauthenticatedRouteMixin, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {

    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    applicationService: Ember.inject.service('application-service'),

    init: function init() {
      this._super();
    },
    beforeModel: function beforeModel(transition) {
      if (transition.queryParams['exchange_token']) {
        this.controllerFor('login').send('authenticate', {
          access_token: transition.queryParams['exchange_token'] // eslint-disable-line camelcase
        });
      }

      if (!this.get('session.isAuthenticated')) {
        this.singleSignOnRedirect();
      } else {
        this.transitionTo(this.get('currentUser.firstAllowedRoute'));
      }
    },
    singleSignOnRedirect: function singleSignOnRedirect() {
      var _this = this;

      Ember.$.ajax({
        url: this.get('applicationService.apiHost') + '/oi_tenant_roles/v1/ssolookup/',
        data: {
          sub_domain: window.location.host.split('.')[0] // eslint-disable-line camelcase
        }
      }).then(function (res) {
        if (res.length > 0) {
          window.location.replace('https://' + window.location.host.split('.')[0] + '.ocean-insights.com' + res[0] + ' ');
        }
      }).catch(function (e) {
        return _this.controller.set('errorMessage', e);
      });
    },


    actions: {
      willTransition: function willTransition() {
        // reset a possible error message after failed login
        this.controller.set('errorMessage', '');
      }
    },

    setupController: function setupController(controller) {
      var url = 'https://hs6sh7rskdsn.statuspage.io/api/v2/summary.json';
      var incidentsUrl = 'https://hs6sh7rskdsn.statuspage.io/api/v2/incidents.json';
      Promise.all([(0, _fetch.default)(url).then(function (response) {
        return response.json();
      }), (0, _fetch.default)(incidentsUrl).then(function (response) {
        return response.json();
      })]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            componentSummary = _ref2[0],
            incidentSummary = _ref2[1];

        return controller.setProperties({
          componentSummary: componentSummary, incidentSummary: incidentSummary
        });
      });
    },


    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('errorMessage', '');
      }
    }
  });
});