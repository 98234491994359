define('css2/instance-initializers/raven-setup', ['exports', 'ember-cli-sentry', 'css2/config/environment'], function (exports, _emberCliSentry, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'sentry-setup',
    initialize: function initialize(application) {
      (0, _emberCliSentry.initialize)(application.lookup ? application : application.container, _environment.default);
    }
  };
});