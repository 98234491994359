define('css2/components/oi-bar', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['oi-bar'],
    transitTime: 0,
    timespan: 0,
    earliestDeparture: null,
    departure: null,

    percent: Ember.computed('departure', 'earliestDeparture', 'timespan', 'transitTime', function () {

      // when the earliestDeparture is at 2019-01-01 15:00 and a ships
      // departure is at 2019-01-02 09:00 the difference in days would be 0 days
      // in fact the user only sees dates (no time) in the results list, so it would be
      // confusing for him to see the offset at 0
      // so we only compare dates here , and we cut the time off
      var earliestDeparture = (0, _moment.default)(this.get('earliestDeparture')).format('YYYY-MM-DD');
      var departure = new Date(this.get('departure'));
      var timespan = this.get('timespan');
      var transitTime = this.get('transitTime');
      var diffDays = _moment.default.utc(departure).diff(_moment.default.utc(earliestDeparture), 'days');
      var offPercent = Math.ceil(100 * diffDays / timespan);
      var barPercent = Math.ceil(100 * transitTime / timespan);

      // catching rounding and dateconversion fuzziness here
      // offsetPercent has precedence to avoid departure
      // comparison woes
      if (offPercent + barPercent > 100) {
        barPercent = 100 - offPercent;
      }

      return {
        off: Ember.String.htmlSafe('width: ' + offPercent + '%'),
        bar: Ember.String.htmlSafe('width: ' + barPercent + '%')
      };
    })
  });
});