define('css2/helpers/shorten-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.shortenText = shortenText;
  function shortenText(params /*, hash*/) {
    if (params.length != 2) {
      return 'Illegal number of Arguments! Hint: Must be two.';
    }
    if (typeof params[0] !== 'string' || typeof params[1] !== 'number') {
      return '--';
    }
    var toBeShortenedString = params[0];
    var length = params[1];
    var result = null;

    if (toBeShortenedString.length > length) {
      result = toBeShortenedString.substring(0, length - 3) + '...';
    } else {
      result = toBeShortenedString;
    }

    return Ember.String.htmlSafe('' + result);
  }

  exports.default = Ember.Helper.helper(shortenText);
});