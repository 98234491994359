define('css2/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember-get-config', 'fetch'], function (exports, _applicationRouteMixin, _emberGetConfig, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {

    counter: 0,
    session: Ember.inject.service('session'),
    raven: Ember.inject.service('raven'),
    currentUser: Ember.inject.service('current-user'),
    applicationService: Ember.inject.service('application-service'),
    router: Ember.inject.service('router'),

    init: function init() {
      this._super.apply(this, arguments);
      this.metronome();
      this.router.addObserver('currentURL', this, 'segmentPageChanged');
    },

    metronome: function metronome() {
      var _this = this;

      this.checkVersion();
      if (_emberGetConfig.default.environment !== 'test') {
        Ember.run.later(this, function () {
          return _this.metronome();
        }, 600000); // tick all 10 minutes
      }
    },

    setupController: function setupController(controller) {
      var statusUrl = 'https://status.project44.com/api/v2/status.json'; //'https://hs6sh7rskdsn.statuspage.io/api/v2/status.json';
      (0, _fetch.default)(statusUrl).then(function (response) {
        return response.json();
      }).then(function (statusSummary) {
        return controller.setProperties({ statusSummary: statusSummary });
      });
    },
    checkVersion: function checkVersion() {
      var _this2 = this;

      var alreadyKnownVersion = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var isFirst = arguments[1];

      if (this.controller) {
        this.controller.checkForNotifications();
      }
      // ^ contoller not there yet on first check, will be notified on sessionAuthenticated, too

      var _config$APP$version$s = _emberGetConfig.default.APP.version.split('+'),
          _config$APP$version$s2 = _slicedToArray(_config$APP$version$s, 2),
          currentVersion = _config$APP$version$s2[0],
          currentHash = _config$APP$version$s2[1];

      currentVersion = alreadyKnownVersion.version || currentVersion;
      // if the user already clicked on "continue with current" even after
      // a new version was found, do not nag again for the same new version
      currentHash = alreadyKnownVersion.hash || currentHash;
      return Ember.$.ajax('/version', { cache: false }).then(function (versionFileContent) {
        var env = _emberGetConfig.default.environment;

        var _versionFileContent$s = versionFileContent.split('\n')[0].split('+'),
            _versionFileContent$s2 = _slicedToArray(_versionFileContent$s, 2),
            latestVersion = _versionFileContent$s2[0],
            latestHash = _versionFileContent$s2[1];

        if (env === 'development') {
          // eslint-disable-next-line no-console
          console.log('version check', latestVersion, latestHash, currentVersion, currentHash, alreadyKnownVersion, isFirst);
        }
        if (!currentHash || !latestHash) {
          _this2.raven.captureMessage('The \'missing hash\' bug is back! ' + currentHash + '|' + latestHash, {
            level: 'warning',
            fingerprint: ['The "missing hash" bug is back'],
            extra: { currentHash: currentHash, currentVersion: currentVersion, versionFileContent: versionFileContent }
          });
        }
        var hashesPresentAndDifferent = currentHash && latestHash && currentHash !== latestHash;
        var versionChanged = latestVersion !== currentVersion || env !== 'development' && hashesPresentAndDifferent;
        var changeMessage = versionFileContent.split('\n')[1];
        if (versionChanged && (isFirst || changeMessage && changeMessage !== '!no')) {
          if (changeMessage === '!auto' || isFirst) {
            _this2.raven.captureMessage('CSS Reload due to update', {
              level: 'debug',
              extra: {
                env: env,
                changeMessage: changeMessage,
                latestVersion: latestVersion,
                currentVersion: currentVersion,
                latestHash: latestHash,
                currentHash: currentHash
              }
            });
            document.location.reload(true);
          }
          _this2.controller.set('showNewAppVersionModal', changeMessage || true);
        }
        return { version: latestVersion, hash: latestHash };
      }).catch(console.error); // eslint-disable-line no-console
    },
    beforeModel: function beforeModel() {
      if (this.get('session.isAuthenticated')) {
        this.get('store').findAll('incident');
        this.get('store').findAll('carrier');
      } else {
        if (!this.get('currentUser.isSsoManaged')) {
          this.jwtAuth();
        }
      }
      // we need the user again, if the browser gets refreshed
      return this.loadCurrentUser();
    },
    jwtAuth: function jwtAuth() {
      var _this3 = this;

      this.get('session').authenticate('authenticator:jwt').then(function () {
        _this3.get('currentUser').load().catch(function () {
          return _this3.get('session').invalidate();
        });
      });
    },
    segmentPageChanged: function segmentPageChanged() {
      var _window = window,
          analytics = _window.analytics;

      if (analytics) {
        analytics.page({
          path: window.location.pathname + window.location.hash,
          referrer: window.location.referrer,
          search: window.location.search,
          title: 'Container Sailing Schedules',
          url: window.location.origin
        });
      }
    },
    segmentIdentify: function segmentIdentify(user) {
      var _window2 = window,
          analytics = _window2.analytics;

      if (analytics) {
        analytics.identify(user.username, {
          userId: user.username,
          email: user.email,
          displayName: user.fullname,
          networkRole: 'CSS-LEGACY-USER'
        });
      }
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      var currentUser = this.loadCurrentUser();
      var session = this;

      if (this.get('session.session.authenticator') !== 'authenticator:jwt') {
        currentUser.then(function () {
          var userData = session.get('currentUser.user.data');
          session.segmentIdentify(userData);
        });
      }
    },
    logoutOkta: function logoutOkta(oktaUrl) {
      return Ember.$.ajax({
        url: oktaUrl + '/api/v1/sessions/me',
        type: 'DELETE',
        xhrFields: { withCredentials: true }
      });
    },
    invalidateSession: function invalidateSession() {
      var _this4 = this;

      this.get('session').invalidate().catch(function () {
        _this4.transitionTo('login');
      });
    },
    sessionInvalidated: function sessionInvalidated() {
      var _super = this._super,
          logoutUrls = this.logoutUrls;

      if (logoutUrls && logoutUrls.length > 0) {
        if (logoutUrls.length === 1) {
          window.location.assign(logoutUrls[0]);
        } else {
          for (var step = 1; step < logoutUrls.length; step += 1) {
            window.open(logoutUrls[step], '_blank').focus();
          }
          window.location.assign(logoutUrls[0]);
        }
      } else {
        _super.call(this);
      }
    },
    createNextTransitionURL: function createNextTransitionURL(transition) {
      var route = '/' + transition.intent.name.replace('.', '/');

      var contexts = transition.intent.contexts;
      var qp = transition.intent.queryParams;
      var keys = Object.keys(qp);

      // special case for p2psearchorder with QueryLog-ID
      if (contexts.length > 0) {
        route = route.replace('/results', '');
        contexts.forEach(function (item) {
          route += '/' + item;
        });
      }

      if (keys.length == 0) {
        return route;
      }

      var queryParams = keys.map(function (key) {
        return key + '=' + qp[key];
      }).join('&');
      return route + '?' + queryParams;
    },
    loadCurrentUser: function loadCurrentUser() {
      var _this5 = this;

      return this.get('currentUser').load().catch(function () {
        return _this5.get('session').invalidate();
      });
    },

    actions: {
      willTransition: function willTransition(transition) {
        // because only strings can be stored in localStorage we have to create the url with queryParams as a string
        if (transition.intent.name) {
          var nextURL = this.createNextTransitionURL(transition);
          window.localStorage.setItem('currentTransition', nextURL);
        }
      },
      logout: function logout() {
        this.set('logoutUrls', undefined);
        var validTokens = this.get('session.data.authenticated.valid_tokens');
        if (validTokens && validTokens.length > 0) {
          var host = window.location.host;


          var logoutUrls = [];
          validTokens.forEach(function (token) {
            var baseLogoutUrl = _emberGetConfig.default.APP.P44_LOGOUT_URLS[host + '_' + token];
            var logoutUrl = baseLogoutUrl + '?afterLogoutRedirectUri=https%3A%2F%2F' + host + '%0A';
            logoutUrls.push(logoutUrl);
          });
          this.set('logoutUrls', logoutUrls);
        }
        this.invalidateSession(this);
      }
    }
  });
});