define('css2/serializers/p2pvoyage', ['exports', 'ember-data', 'css2/serializers/drf'], function (exports, _emberData, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    //attrs: {
    //  subscription_events: {embedded: 'always'},
    //}

    attrs: {
      carrier: { embedded: 'always' },
      pod: { embedded: 'always' },
      pol: { embedded: 'always' },
      legs: { embedded: 'always' },
      alliance: { embedded: 'always' }
    }

  });
});