define('css2/helpers/lighten-darken-hex', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.lightenDarkenHex = lightenDarkenHex;
    function lightenDarkenHex(color, percent) {
        var saturation = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
        var grey = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;

        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = parseBasicColorInt(R, percent);
        G = parseBasicColorInt(G, percent);
        B = parseBasicColorInt(B, percent);

        R = capAtOverflow(R);
        G = capAtOverflow(G);
        B = capAtOverflow(B);

        var RR = colorToHex(R);
        var GG = colorToHex(G);
        var BB = colorToHex(B);

        var newColor = '#' + RR + GG + BB;
        if (saturation && grey) {
            newColor = updateSaturationAndValue(newColor, saturation, grey);
        }
        return newColor;
    }

    exports.default = Ember.Helper.helper(lightenDarkenHex);


    function parseBasicColorInt(color, percent) {
        return parseInt(color * (100 + percent) / 100);
    }

    function capAtOverflow(color) {
        return color < 255 ? color : 255;
    }

    function colorToHex(color) {
        return color.toString(16).length == 1 ? '0' + color.toString(16) : color.toString(16);
    }

    function updateSaturationAndValue(color, saturation, grey) {
        var col = hexToRgb(color);
        var sat = saturation;

        col.r = Math.round(col.r * sat + grey * (1 - sat));
        col.g = Math.round(col.g * sat + grey * (1 - sat));
        col.b = Math.round(col.b * sat + grey * (1 - sat));

        var out = rgbToHex(col.r, col.g, col.b);
        return out;
    }

    function componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? '0' + hex : hex;
    }

    function rgbToHex(r, g, b) {
        return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    function hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }
});