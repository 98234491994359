define('css2/routes/index', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    init: function init() {
      this._super.apply(this, arguments);
    },

    currentUser: Ember.inject.service('current-user'),

    queryParams: {
      reset: {
        refreshModel: true
      }
    },

    beforeModel: function beforeModel(transition) {
      if (transition.queryParams.reset) {
        var csneResultsController = this.controllerFor('csne.results');
        var vesselscheduleResultsController = this.controllerFor('vesselschedule.results');
        var p2psearchorderController = this.controllerFor('p2psearchorder');
        var p2psearchorderResultsController = this.controllerFor('p2psearchorder.results');
        var applicationController = this.controllerFor('application');
        csneResultsController.flush();
        vesselscheduleResultsController.flush();
        p2psearchorderResultsController.flush();
        p2psearchorderController.flush();
        applicationController.flush();
        Ember.$('#gfx-holder').show();
      }

      if (this.get('currentUser.user')) {
        this.transitionTo(this.get('currentUser.firstAllowedRoute'));
      } else {
        // this.get('currentUser').load().catch(() => this.get('session').invalidate());
        // no user yet, so go to the route that waits for the user to come up.
        return this.transitionTo('home');
      }
    }
  });
});