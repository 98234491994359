define('css2/helpers/oi-shorten-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.oiShortenText = oiShortenText;
  function oiShortenText(params /*, hash*/) {
    if (params.length != 4) {
      return 'Illegal number of Arguments! Hint: Must be two.';
    }
    if (typeof params[0] !== 'string' || typeof params[1] !== 'number' || typeof params[2] !== 'string' || typeof params[3] !== 'string') {
      return '--';
    }
    var fullStr = params[0];
    var strLen = params[1];
    var mode = params[2];
    var filler = params[3];
    var result = null;

    if (mode === 'center') {
      if (fullStr.length <= strLen) return fullStr;

      filler = filler || '...';

      var sepLen = filler.length,
          charsToShow = strLen - sepLen,
          frontChars = Math.ceil(charsToShow / 2),
          backChars = Math.floor(charsToShow / 2);

      result = fullStr.substr(0, frontChars) + filler + fullStr.substr(fullStr.length - backChars);
    }

    //var result = null;
    return result;
  }

  exports.default = Ember.Helper.helper(oiShortenText);
});