define('css2/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments); // eslint-disable-line prefer-rest-params
    },


    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    applicationService: Ember.inject.service('application-service'),

    openExpirationDialog: false,
    currentYear: Ember.computed(function () {
      return new Date().getFullYear();
    }),
    apiHost: Ember.computed(function () {
      return this.get('applicationService.apiHost');
    }),

    hasZendeskEnabled: Ember.computed(function () {
      return typeof zE !== 'undefined';
    }),

    linkToVoc: Ember.computed(function () {
      return this.get('applicationService.linkToVoc');
    }),

    currentRouteName: Ember.computed.reads('this.currentRouteName'),

    actions: {
      zeActivate: function zeActivate() {
        if (typeof zE !== 'undefined') {

          zE(function () {
            zE.activate({ hideOnClose: true });
          });
        }
      },

      reload: function reload() {
        document.location.reload(true);
      }
    },

    flush: function flush() {
      this.set('memorizedHandle', null);
    }
  });
});