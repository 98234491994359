define('css2/components/oi-power-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    actions: {
      handleFocus: function handleFocus(select) {
        if (!select.isActive) {
          select.actions.open();
        }
      },
      handleClose: function handleClose(select, e) {
        if (e !== undefined) {
          if (e.key == 'Tab') {
            select.actions.select(select.highlighted, e);
          }
          select.actions.close();
        }
      }
    }
  });
});