define('css2/controllers/reset', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service('current-user'),
    session: Ember.inject.service('session'),
    applicationService: Ember.inject.service('application-service'),

    queryParams: ['uid', 'token', 'accountname', 'username', 'invite', 'email'],
    uid: null,
    token: null,
    email: null,
    accountname: null,

    identification: '',
    errorMessage: '',

    emailForResetLink: Ember.computed(function () {
      return this.get('email') ? this.get('email') : '';
    }),
    hasCredentialsAndAccount: Ember.computed.and('uid', 'token', 'accountname'),
    isAuthenticated: Ember.computed.alias('session.isAuthenticated'),
    isChangeOrReset: Ember.computed.or('hasCredentialsAndAccount', 'isAuthenticated'),

    disabled: Ember.computed('identification', function () {
      return this.get('identification.length') < 5 || this.get('identification').indexOf('@') === -1;
    }),

    disabledReset: Ember.computed('newpasswordone', 'newpasswordtwo', function () {
      var p1 = this.get('newpasswordone');
      var p2 = this.get('newpasswordtwo');
      return !p1 || !p2;
    }),

    hasGottenCredentialsAndAccount: Ember.computed('uid', 'token', 'accountname', function () {
      var uid = this.get('uid');
      var token = this.get('token');
      var accountname = this.get('accountname');
      return uid != null && token != null && accountname != null;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.$('#notificationOne').hide();
      Ember.$('#notificationTwo').hide();
    },
    /* eslint-disable camelcase */
    resetPasswordForChallengeAndUser: function resetPasswordForChallengeAndUser(givenUid, givenToken, givenAccountname, givenPasswordOne, givenPasswordTwo) {
      this.set('errorMessage', '');
      var model = this.get('model');
      var _this = this;
      Ember.$.ajax({
        url: this.get('applicationService.apiHost') + '/customers/password/reset/confirm/' + (model.isInvite ? '?invite=1' : ''),
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        crossDomain: true,
        xhrFields: { withCredentials: _emberGetConfig.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS },
        data: JSON.stringify({
          new_password1: givenPasswordOne,
          new_password2: givenPasswordTwo,
          uid: givenUid,
          token: givenToken
        })
      }).then(function (response) {

        _this.set('errorMessage', '');
        /*
        _this.set('token', null);
        _this.set('uid', null);
        _this.set('accountname', null);
         */
        if (response.detail || response.success) {
          _this.notifySuccess('passwordSet');
        } else {
          _this.set('errorMessage', 'Unknown error');
        }
      }, function (reason) {
        var errorString = 'Bad request';
        if (reason.status === 400) {
          var errorObject = reason.responseJSON;

          if (errorObject.token) {
            _this.set('token', null);
            _this.set('uid', null);
            _this.set('accountname', null);
            errorString = 'Invalid token. Expired?';
          }

          if (errorObject.new_password2) {
            _this.set('newpasswordone', null);
            _this.set('newpasswordtwo', null);
            errorString = _this.makeErrorStringFromArray(errorObject);
          }
        }
        _this.set('errorMessage', errorString);
      });
    },

    changePassword: function changePassword(app, givenCurrentPassword, givenPasswordOne, givenPasswordTwo) {
      var headers = {
        'Authorization': 'Bearer ' + this.get('session.data.authenticated.access_token')
      };

      this.set('errorMessage', '');
      var _this = this;
      Ember.$.ajax({
        url: this.get('applicationService.apiHost') + '/customers/password/change/',
        headers: headers,
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        crossDomain: true,
        xhrFields: { withCredentials: _emberGetConfig.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS },
        data: JSON.stringify({
          app: app,
          new_password1: givenPasswordOne,
          new_password2: givenPasswordTwo,
          old_password: givenCurrentPassword
        })
      }).then(function (response) {
        _this.set('errorMessage', '');

        if (response.detail || response.success) {
          _this.notifySuccess('passwordSet');
        } else {
          _this.set('errorMessage', 'Unknown error');
        }
      }, function (reason) {
        var errorString = 'Bad request';
        if (reason.status === 400) {
          var errorObject = reason.responseJSON;
          if (errorObject.new_password2) {
            _this.set('newpasswordone', null);
            _this.set('newpasswordtwo', null);
            errorString = _this.makeErrorStringFromArray(errorObject);
          }
          if (errorObject.old_password) {
            _this.set('currentPassword', null);
            errorString = _this.makeErrorStringFromArray(errorObject);
          }
        }
        _this.set('errorMessage', errorString);
      });
    },

    makeErrorStringFromArray: function makeErrorStringFromArray(errorObject) {
      var str = '';
      var node = errorObject.new_password2 || errorObject.token || errorObject.old_password;
      node.forEach(function (elem) {
        str += elem + ' ';
      });
      return str;
    },


    /* eslint-enable camelcase */

    requestPasswordResetForUser: function requestPasswordResetForUser(username) {
      this.set('errorMessage', '');
      var model = this.get('model');
      var _this = this;

      Ember.$.ajax({
        url: this.get('applicationService.apiHost') + '/customers/password/reset/' + (model.isInvite ? '?invite=1' : ''),
        type: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        crossDomain: true,
        xhrFields: { withCredentials: _emberGetConfig.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS },
        data: JSON.stringify({
          email: username
        })
      }).then(function (response) {
        _this.set('errorMessage', '');
        if (response.detail || response.success) {
          _this.notifySuccess('emailSent');
        } else {
          _this.set('errorMessage', 'Answer from server is garbled. Try again.');
        }
      }, function (reason) {
        _this.set('errorMessage', 'A server side error occurred. Try again later. ');
        this.get('raven').captureMessage('Password reset trouble', {
          level: 'error',
          extra: {
            reason: reason.status
          }
        });
      });
    },

    notifySuccess: function notifySuccess(successType) {
      this.setProperties({
        notificationType: successType,
        newpasswordone: '',
        newpasswordtwo: '',
        currentPassword: null
      });
      Ember.$('#notification').hide();
      Ember.$('#form').fadeOut('slow', function () {
        return Ember.$('#notification').fadeIn('slow');
      });
    },


    actions: {
      requestReset: function requestReset(e) {
        if (e) {
          var givenUsername = this.get('emailForResetLink');
          if (givenUsername !== '') {
            this.requestPasswordResetForUser(givenUsername);
          } else {
            this.set('errorMessage', 'No email address given!');
          }
        }
      },

      requestPasswordChange: function requestPasswordChange(e) {
        if (e) {
          var givenPasswordOne = this.get('newpasswordone');
          var givenPasswordTwo = this.get('newpasswordtwo');
          var givenUid = this.get('uid');
          var givenToken = this.get('token');
          var givenAccountname = this.get('accountname');

          this.resetPasswordForChallengeAndUser(givenUid, givenToken, givenAccountname, givenPasswordOne, givenPasswordTwo);
        }
      },

      requestPasswordChange2: function requestPasswordChange2(e) {
        if (e) {
          var givenCurrentPassword = this.get('currentPassword');
          var givenPasswordOne = this.get('newpasswordone');
          var givenPasswordTwo = this.get('newpasswordtwo');
          var app = 'CSW';
          this.changePassword(app, givenCurrentPassword, givenPasswordOne, givenPasswordTwo);
        }
      }
    }

  });
});