define('css2/controllers/p2psearchorder/results', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    session: Ember.inject.service('session'),
    sortMode: 0,
    ed: null,
    la: null,
    carrierSortProperties: null,
    allianceSortProperties: null,
    selectedCarriers: null,
    selectedAlliances: null,
    selectedTransshipments: null,
    currentHandle: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('carrierSortProperties', ['shortName']);
      this.set('allianceSortProperties', ['id']);
      this.set('selectedCarriers', []);
      this.set('selectedAlliances', []);
      this.set('selectedTransshipments', []);

      this.set('sortDefinition', {
        name: 'Departure and Transit Time',
        sortArray: ['pureEtdLocalDate', 'transitTime']
      });
      this.set('sortDefinitions', [{
        id: 0,
        name: 'Departure and Transit Time',
        sortArray: ['pureEtdLocalDate', 'transitTime']
      }, {
        id: 1,
        name: 'Transit Time and Departure',
        sortArray: ['transitTime', 'pureEtdLocalDate']
      }, {
        id: 2,
        name: 'Arrival and Transit Time',
        sortArray: ['pureEtaLocalDate', 'transitTime']
      }, {
        id: 3,
        name: 'Transshipments',
        sortArray: ['transshipmentCount:DESC']
      }, {
        id: 4,
        name: 'Carrier Name',
        sortArray: ['carrier.shortName']
      }, {
        id: 5,
        name: 'Departure Date',
        sortArray: ['pureEtdLocalDate']
      }, {
        id: 6,
        name: 'Arrival Date',
        sortArray: ['pureEtaLocalDate']
      }]);
    },


    /**
     * Initialize
     * This is triggered from the route after getting model to init the state.
     */
    initialize: function initialize() {
      this.set('shortestTransitInSelection', this.get('shortestTransit'));
      this.set('longestTransitInSelection', this.get('longestTransit'));
      var allUniqueSortedCarriersCopy = this.get('sortedUniqueAllCarriers').slice();
      var allUniqueSortedAlliancesCopy = this.get('sortedUniqueAllAlliances').slice();
      var allTransshipmentsCopy = this.get('maximumTransshipments').slice();
      this.set('selectedCarriers', allUniqueSortedCarriersCopy);
      this.set('selectedAlliances', allUniqueSortedAlliancesCopy);
      this.set('selectedTransshipments', allTransshipmentsCopy);
    },


    filteredEntries: Ember.computed('entries.[]', 'shortestTransitInSelection', 'longestTransitInSelection', 'selectedCarriers.[]', 'selectedAlliances.[]', 'selectedTransshipments.[]', function () {
      var entries = this.get('entries');
      if (!entries) {
        return [];
      }
      var selectedCarrierIds = this.selectedCarriers.mapBy('id');
      var selectedAllianceIds = this.selectedAlliances.mapBy('id');
      var selectedTsCounts = this.selectedTransshipments.mapBy('value');
      var minTransit = this.shortestTransitInSelection;
      var maxTransit = this.longestTransitInSelection;
      return entries.filter(function (entry) {
        var transitTime = entry.get('transitTime');
        return selectedCarrierIds.includes(entry.get('carrier.id')) && selectedAllianceIds.includes(entry.get('alliance.id')) && selectedTsCounts.includes(entry.get('transshipmentCount')) && minTransit <= transitTime && transitTime <= maxTransit;
      });
    }),

    sortedEntries: Ember.computed.sort('filteredEntries', 'sortDefinition.sortArray'),

    // Aggregate der Gesamtmenge
    allTransits: Ember.computed.mapBy('entries', 'transitTime'),
    shortestTransit: Ember.computed.min('allTransits'),
    longestTransit: Ember.computed.max('allTransits'),

    allCarriers: Ember.computed.mapBy('entries', 'carrier'),
    uniqueAllCarriers: Ember.computed.uniqBy('allCarriers', 'shortName'),
    sortedUniqueAllCarriers: Ember.computed.sort('uniqueAllCarriers', 'carrierSortProperties'),

    allAlliances: Ember.computed.mapBy('entries', 'alliance'),
    uniqueAllAlliances: Ember.computed.uniqBy('allAlliances', 'name'),
    sortedUniqueAllAlliances: Ember.computed.sort('uniqueAllAlliances', 'allianceSortProperties'),

    // Aggregate der gefilterten Menge
    filteredTransits: Ember.computed.mapBy('filteredEntries', 'transitTime'),
    min: Ember.computed.min('filteredTransits'),
    max: Ember.computed.max('filteredTransits'),

    allFilteredCarriers: Ember.computed.mapBy('filteredEntries', 'carrier'),
    uniqueFilteredCarriers: Ember.computed.uniqBy('allFilteredCarriers', 'shortName'),
    sortedUniqueFilteredCarriers: Ember.computed.sort('uniqueFilteredCarriers', 'carrierSortProperties'),

    allFilteredAlliances: Ember.computed.mapBy('filteredEntries', 'alliance'),
    uniqueFilteredAlliances: Ember.computed.uniqBy('allFilteredAlliances', 'name'),
    sortedUniqueFilteredAlliances: Ember.computed.sort('uniqueFilteredAlliances', 'allianceSortProperties'),

    earliestDeparture: Ember.computed('entries.[]', function () {
      var entries = this.get('entries');
      if (!entries) {
        return null;
      }

      var earliestDeparture = null;
      entries.forEach(function (item) {
        var etd = new Date(item.get('etd'));
        if (earliestDeparture === null || etd.getTime() <= earliestDeparture.getTime()) {
          earliestDeparture = etd;
        }
      });
      return earliestDeparture;
    }),

    maximumTransshipments: Ember.computed('entries.[]', function () {
      var legs = [];
      var entries = this.get('entries');
      if (!entries) {
        return null;
      }
      entries.forEach(function (entry) {
        var tsCount = entry.get('transshipmentCount');
        if (legs.filter(function (leg) {
          return leg.value === tsCount;
        }).length === 0) {
          var label = tsCount === 0 ? 'direct' : tsCount + ' transshipment' + (tsCount > 1 ? 's' : '');
          legs.push({ value: tsCount, label: label });
        }
      });
      return legs.sort(this.compare);
    }),

    latestArrival: Ember.computed('entries.[]', function () {
      var entries = this.get('entries');
      if (!entries) {
        return null;
      }

      var latestArrival = null;
      entries.forEach(function (item) {
        var eta = new Date(item.get('eta'));
        if (latestArrival === null || eta.getTime() >= latestArrival.getTime()) {
          latestArrival = eta;
        }
      });
      return latestArrival;
    }),

    timespan: Ember.computed('earliestDeparture', 'latestArrival', function () {
      var ed = this.get('earliestDeparture'); // earliest departure
      var la = this.get('latestArrival'); // latest arrival

      if (!ed || !la) {
        return 0;
      }
      //let timeDiff = Math.abs(la.getTime() - ed.getTime());
      //var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return _moment.default.utc(la).diff(_moment.default.utc(ed), 'days');
    }),

    sliderStart: Ember.computed('shortestTransitInSelection', 'longestTransitInSelection', function () {
      return [this.get('shortestTransitInSelection'), this.get('longestTransitInSelection')];
    }),

    retrieveSpecificObject: function retrieveSpecificObject(str, list, field) {
      var i = void 0;
      for (i = 0; i < list.length; i++) {
        var current = list[i][field];
        // use condition here to prevent crashing app when list of objects is passed
        if (current === undefined) {
          current = list[i].get(field);
        }
        if (current === str) {
          return list[i];
        }
      }
      return null;
    },
    deleteSpecificObject: function deleteSpecificObject(array, name, field) {
      var newArray = [];
      for (var i = 0; i < array.length; i++) {
        var current = array[i][field];

        // use condition here to prevent crashing app when list of objects is passed
        if (current === undefined) {
          current = array[i].get(field);
        }
        if (current !== name) {
          newArray.pushObject(array[i]);
        }
      }
      return newArray;
      //shortName === arg;
    },


    saneMin: function saneMin(value) {
      return value === Number.POSITIVE_INFINITY || value === Number.NEGATIVE_INFINITY ? this.get('shortestTransit') : value;
    },

    saneMax: function saneMax(value) {
      return value === Number.POSITIVE_INFINITY || value === Number.NEGATIVE_INFINITY ? this.get('longestTransit') : value;
    },

    setSelectedTransshipments: function setSelectedTransshipments(bla) {
      var filteredEntriesTs = bla.map(function (item) {
        return item.get('transshipmentCount');
      });
      this.set('selectedTransshipments', this.get('maximumTransshipments').filter(function (e) {
        return filteredEntriesTs.includes(e.value);
      }));
    },
    compare: function compare(a, b) {
      // Helper to sort Object by value
      var valueA = a.value;
      var valueB = b.value;

      var comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
      return comparison;
    },


    actions: {
      resetAll: function resetAll() {
        this.set('shortestTransitInSelection', this.get('shortestTransit'));
        this.set('longestTransitInSelection', this.get('longestTransit'));
        this.set('selectedCarriers', this.get('sortedUniqueAllCarriers').copy());
        this.set('selectedAlliances', this.get('sortedUniqueAllAlliances').copy());
        this.set('selectedTransshipments', this.get('maximumTransshipments').copy());
        this.set('filterFlag', false);
        var temp = this.get('entries');
        this.set('entries', null);
        this.set('entries', temp);
      },
      updateCarrierSelection: function updateCarrierSelection(fn, arg) {
        if (fn === 'removeObject') {
          var newSelection = this.deleteSpecificObject(this.get('selectedCarriers'), arg, 'shortName');
          this.set('selectedCarriers', newSelection);
        } else {
          this.set('selectedAlliances', this.get('entries').mapBy('alliance'));
          this.setSelectedTransshipments(this.get('entries'));

          var carrierToAdd = this.retrieveSpecificObject(arg, this.get('sortedUniqueAllCarriers'), 'shortName');
          var currentlySelectedCarriers = this.get('selectedCarriers');
          currentlySelectedCarriers.push(carrierToAdd);
          this.set('selectedCarriers', currentlySelectedCarriers);
        }
      },
      updateAlliancesSelection: function updateAlliancesSelection(fn, arg) {
        if (fn === 'removeObject') {
          var newSelection = this.deleteSpecificObject(this.get('selectedAlliances'), arg, 'name');
          this.set('selectedAlliances', newSelection);
        } else {
          this.set('selectedCarriers', this.get('entries').mapBy('carrier'));
          this.setSelectedTransshipments(this.get('entries'));

          var allianceToAdd = this.retrieveSpecificObject(arg, this.get('sortedUniqueAllAlliances'), 'name');
          var currentlySelectedAlliances = this.get('selectedAlliances');
          currentlySelectedAlliances.push(allianceToAdd);
          this.set('selectedAlliances', currentlySelectedAlliances);
        }
      },
      updateTransshipmentSelection: function updateTransshipmentSelection(fn, arg) {
        if (fn === 'removeObject') {
          var newSelection = this.deleteSpecificObject(this.get('selectedTransshipments'), arg, 'value');
          this.set('selectedTransshipments', newSelection);
        } else {
          this.set('selectedAlliances', this.get('entries').mapBy('alliance'));
          this.set('selectedCarriers', this.get('entries').mapBy('carrier'));
          var transshipmentsToAdd = this.retrieveSpecificObject(arg, this.get('maximumTransshipments'), 'value');
          var currentlySelectedTransshipments = this.get('selectedTransshipments');
          currentlySelectedTransshipments.push(transshipmentsToAdd);
          this.set('selectedTransshipments', currentlySelectedTransshipments);
        }
      },


      changeSortMode: function changeSortMode() /*arg*/{
        if (this.get('sortMode') === this.get('sortDefinitions').length - 1) {
          this.set('sortMode', 0);
        } else {
          this.set('sortMode', this.get('sortMode') + 1);
        }
        this.set('sortDefinition', this.get('sortDefinitions')[this.get('sortMode')]);
      },

      clear: function clear() {
        this.set('shortestTransitInSelection', this.get('shortestTransit'));
        this.set('longestTransitInSelection', this.get('longestTransit'));
        this.set('selectedCarriers', []);
        this.set('selectedAlliances', []);
        this.set('selectedTransshipments', []);
      },

      sliderChanged: function sliderChanged(value) {

        var oldmin = this.get('min');
        var oldmax = this.get('max');

        // 2. do we narrow or widen our selection?
        if (oldmin > value[0] || oldmax < value[1]) {
          // as we widen the selection we reset the colors selection
          this.set('selectedCarriers', this.get('sortedUniqueAllCarriers'));
          this.set('selectedAlliances', this.get('sortedUniqueAllAlliances'));
          this.setSelectedTransshipments(this.get('entries'));
        }

        // 3. set new selection boundaries
        this.set('shortestTransitInSelection', this.saneMin(value[0]));
        this.set('longestTransitInSelection', this.saneMax(value[1]));

        this.set('selectedCarriers', this.get('sortedUniqueFilteredCarriers').copy());
        this.set('selectedAlliances', this.get('sortedUniqueFilteredAlliances').copy());
        this.setSelectedTransshipments(this.get('filteredEntries'));

        // 6. check if the slider gets back to no selection at all
        if (value[0] === this.get('shortestTransit') && value[1] === this.get('longestTransit')) {
          this.set('selectedCarriers', this.get('sortedUniqueAllCarriers').copy());
          this.set('selectedAlliances', this.get('sortedUniqueAllAlliances').copy());
        }
      }
    },

    flush: function flush() {
      this.set('currentHandle', null);
      this.set('entries', null);
    }
  });
});