define('css2/controllers/p2psearchorder', ['exports', 'moment', 'ember-local-storage', 'ember-get-config'], function (exports, _moment, _emberLocalStorage, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var WEEK_NUMBER_OPTIONS = [{ title: '2 weeks', value: 2 }, { title: '4 weeks', value: 4 }, { title: '6 weeks', value: 6 }];

  function dateToString(dateOrString) {

    if (typeof dateOrString === 'string') {
      return dateOrString;
    }
    return (0, _moment.default)(dateOrString).format('YYYY-MM-DD');
  }

  exports.default = Ember.Controller.extend({

    recentSearches: (0, _emberLocalStorage.storageFor)('recent-searches'),
    session: Ember.inject.service('session'),
    resultsController: Ember.inject.controller('p2psearchorder.results'),
    applicationController: Ember.inject.controller('application'),
    currentUser: Ember.inject.service('current-user'),
    applicationService: Ember.inject.service('application-service'),

    selectedPolPort: null,
    selectedPodPort: null,
    dateModeIsArrival: false,
    selectedDate: null,
    currentWeekOption: WEEK_NUMBER_OPTIONS[0],
    weekNumberOptions: WEEK_NUMBER_OPTIONS,
    bestRoutesOnly: true,
    nolimit: true,
    openSamePortModal: false,
    needsFeedback: false,
    hasResult: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedDate', new Date());
    },


    buttonDisabled: Ember.computed('selectedPolPort', 'selectedPodPort', function () {
      return !this.selectedPolPort || !this.selectedPodPort || this.selectedPolPort.id === this.selectedPodPort.id;
    }),

    weeks: Ember.computed('currentWeekOption.value', {
      get: function get() {
        return this.currentWeekOption.value;
      },
      set: function set(key, value) {
        this.set('currentWeekOption', WEEK_NUMBER_OPTIONS.find(function (wno) {
          return wno.value === value;
        }));
      }
    }),

    saveP2PSearchOrder: function saveP2PSearchOrder(dateMode) {
      var _get$createRecord;

      var gottenPolId = this.get('selectedPolPort').id;
      var gottenPodId = this.get('selectedPodPort').id;
      var nolimit = this.get('nolimit');

      var self = this;
      var p2psearchorder = this.get('store').createRecord('p2psearchorder', (_get$createRecord = {
        pod: gottenPodId,
        pol: gottenPolId
      }, _defineProperty(_get$createRecord, dateMode.toLowerCase(), dateToString(this.selectedDate)), _defineProperty(_get$createRecord, 'weeks', this.currentWeekOption.value), _defineProperty(_get$createRecord, 'allroutes', !this.bestRoutesOnly), _defineProperty(_get$createRecord, 'nolimit', nolimit), _defineProperty(_get$createRecord, 'results', []), _get$createRecord));

      p2psearchorder.save().then(function (order) {
        self.set('needsFeedback', false);
        self.get('applicationController').set('memorizedHandle', order.get('handle'));
        self.transitionToRoute('p2psearchorder.results', order.get('handle'));
        self.set('hasResult', true);
      }).catch(function (error) {
        // a formerly valid port may have left the building
        if (error.errors[0].detail || error.errors[0].success) {
          self.set('hasResult', true);
          self.set('needsFeedback', false);
          self.transitionToRoute('p2psearchorder.noresults');
        }

        // Addresses CSW-83
        // pretty likely we're not authorized anymore - so invalidate session
        // and use the ESA magic to get redirected to /login route
        if (error.status === 401) {
          this.get('session').invalidate();
        }
      });
    },


    actions: {
      prefill: function prefill(queryDict) {
        // TODO: check if the dict is at least halfway valid
        this.set('selectedPolPort', queryDict.pol);
        this.set('selectedPodPort', queryDict.pod);
        this.set('selectedDate', dateToString(queryDict.date));
        this.set('weeks', queryDict.weeks);
        this.set('dateModeIsArrival', queryDict.dateMode === 'ETA');
        this.set('bestRoutesOnly', queryDict.bestRoutesOnly);
      },
      deleteAll: function deleteAll() {
        this.get('recentSearches').clear();
      },
      toggleDateMode: function toggleDateMode() {
        var isArrival = this.dateModeIsArrival;
        var daysToMove = this.currentWeekOption.value * (isArrival ? -7 : 7);
        var selectedDate = new Date(this.selectedDate);
        selectedDate.setDate(selectedDate.getDate() + daysToMove);
        this.setProperties({
          dateModeIsArrival: !isArrival,
          selectedDate: dateToString(selectedDate)
        });
      },


      /**
       * Fetching ports as you type
       * @param {string} term
       */
      searchPort: function searchPort(term) {
        var _this = this;

        if (term.length < 3) {
          return;
        }
        var headers = {
          'Authorization': 'Bearer ' + this.get('session.data.authenticated.access_token')
        };
        var url = this.get('applicationService.apiHost') + '/cwaapi/v3/ports/?all=1&s=' + term;
        return Ember.$.ajax(url, {
          cache: false,
          headers: headers,
          crossDomain: true,
          xhrFields: { withCredentials: _emberGetConfig.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS }
        }).then(function (result) {
          return result;
        }).catch(function (error) {
          // Addresses CSW-83
          // pretty common that we are not authorized anymore - so invalidate session
          // and use the ESA magic to get redirected to /login route
          if (error.status === 401) {
            _this.get('session').invalidate();
          }
        });
      },


      /**
       * Swaps selectedPolPort & selectedPodPort
       */
      swapPolPod: function swapPolPod() {
        var temp = this.get('selectedPolPort');
        this.set('selectedPolPort', this.get('selectedPodPort'));
        this.set('selectedPodPort', temp);
      },


      /**
       * Checking stuff before issuing a search.
       */
      requestSearch: function requestSearch() {

        this.set('hasError', false);
        this.set('errorMessage', '');

        // tell the nested result controller to clear previous results
        this.get('resultsController').set('completed', false);
        this.get('resultsController').set('entries', []);
        this.get('resultsController').set('pollster', null);

        // that's a bad one
        // if transition to subroute index.p2psearchorder not yet happened - e.g.
        // we need to fake a loading spinner in index template... :-)
        // but only then as the p2psearchorder subroute#s template
        // has their own spinner.
        // this is to have one for sure but to prevent having two of them...

        var url = window.location.href;
        if (url.endsWith('p2psearchorder')) {
          // haven't yet loaded subroute results
          // so set a flag to show a activity indicator
          this.set('needsFeedback', true);
          this.set('hasResult', true);
        } else {
          this.set('needsFeedback', false);
        }

        var pol = this.get('selectedPolPort');
        var pod = this.get('selectedPodPort');

        var dateMode = this.dateModeIsArrival ? 'ETA' : 'ETD';
        var weeks = this.currentWeekOption.value;
        var bestRoutesOnly = this.bestRoutesOnly;
        var date = dateToString(this.selectedDate);

        // generate a dictionary describing the request to save it
        // while persistence is currently done locally (browser's local store)
        // we migtht back it with API later on
        var queryDictToSave = {
          pol: { id: pol.id, name: pol.name, locode: pol.locode },
          pod: { id: pod.id, name: pod.name, locode: pod.locode },
          date: date,
          dateMode: dateMode,
          weeks: weeks,
          bestRoutesOnly: bestRoutesOnly
        };

        this.saveRequest(queryDictToSave);
        this.saveP2PSearchOrder(dateMode);
      }
    },

    saveRequest: function saveRequest(requestDict) {
      var recentSearches = this.get('recentSearches') || [];
      var toFind = JSON.stringify(requestDict);
      var removeAt = null;
      recentSearches.find(function (element, index) {
        if (JSON.stringify(element) === toFind) {
          removeAt = index + 1;
          return true;
        }
      });
      recentSearches.insertAt(0, requestDict);

      if (removeAt || recentSearches.get('length') > 5) {
        recentSearches.removeAt(removeAt || 5);
      }
    },
    flush: function flush() {
      this.set('selectedPolPort', null);
      this.set('selectedPodPort', null);
      this.set('hasResult', false);
    }
  });
});