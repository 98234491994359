define('css2/controllers/vesselschedule', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    session: Ember.inject.service('session'),
    id: null,
    start: null,
    end: null,
    selectedVessel: null,

    buttonDisabled: Ember.computed('start', 'end', 'selectedVessel', function () {

      var selectedVessel = this.get('selectedVessel');
      var start = this.get('start');
      var end = this.get('end');

      if (start > end) {
        Ember.$('.oi-datefield').css('color', 'red');
      } else {
        Ember.$('.oi-datefield').css('color', 'black');
      }
      return !(Ember.isPresent(selectedVessel) && start <= end);
    }),

    store: Ember.inject.service(),
    actions: {
      searchVessel: function searchVessel(term) {
        return this.store.query('vessel', { s: term });
      },
      requestSearch: function requestSearch() {
        if (this.get('selectedVessel.id') !== null) {
          this.send('doSearch' /*, someParams */);
        }
      },


      doSearch: function doSearch() {
        var start = this.get('start');
        var end = this.get('end');
        var id = this.get('selectedVessel.id');
        this.transitionToRoute('vesselschedule.results', { queryParams: { start: start, end: end, id: id } });
      },

      pickStartDate: function pickStartDate(arg) {
        this.set('start', (0, _moment.default)(arg).format('YYYY-MM-DD'));
      },
      pickEndDate: function pickEndDate(arg) {
        this.set('end', (0, _moment.default)(arg).format('YYYY-MM-DD'));
      },


      // handle the modal view actions by bubbling them down
      denyModal: function denyModal() /* element, component */{
        return true;
      },
      approveModal: function approveModal() /* element, component */{
        return true;
      }
    }
  });
});