define('css2/routes/blanksailings/report', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    completed: false,
    model: function model() {
      var _this = this;

      this.set('completed', false);
      return this.get('store').findRecord('blanksailings/report', 'latest').then(function (results) {
        return results;
      }).catch(function (reason) {
        _this.set('completed', true);
        _this.set('errorMessage', reason.message);
        return { services: [] };
      });
    }
  });
});