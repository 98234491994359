define('css2/controllers/portintelligence/results', ['exports', 'css2/constants/general', 'moment'], function (exports, _general, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var searchPlaceholder = 'Search...';

  exports.default = Ember.Controller.extend({
    selectedPort: null,
    legendColors: Ember.computed(function () {
      var weeks = this.get('weeks');

      switch (weeks) {
        case '2':
          return _general.VESSEL_ETA_COLORS_2_WEEKS;
        case '4':
          return _general.VESSEL_ETA_COLORS_4_WEEKS;
        case '6':
          return _general.VESSEL_ETA_COLORS_6_WEEKS;
        default:
          return _general.VESSEL_ETA_COLORS;
      }
    }),

    ports: Ember.computed('selectedPort', function () {
      return this.get('selectedPort') ? [this.get('selectedPort')] : [];
    }),
    columns: Ember.computed('consolidatedVesselList', function () {
      return [{
        'propertyName': 'shipname',
        'title': 'Vessel name',
        'filterPlaceholder': searchPlaceholder,
        'className': 'widthCell'
      }, {
        'propertyName': 'imo',
        'title': 'IMO',
        'filterPlaceholder': searchPlaceholder,
        'className': 'widthCell'
      }, {
        'propertyName': 'eta',
        'title': 'planned ETA',
        'className': 'widthCell',
        'disableFiltering': true
      }, {
        'propertyName': 'cap_teu',
        'title': 'TEU Capacity',
        'className': 'widthCell',
        'disableFiltering': true
      }];
    }),

    data: Ember.computed('sortedVessels', function () {
      var vessels = this.get('sortedVessels');
      var result = [];

      if (vessels) {
        vessels.forEach(function (vesselObj) {
          var abbr = _moment.default.tz(vesselObj.timezone).zoneAbbr() || (0, _moment.default)().tz(_moment.default.tz.guess()).format('z');
          var sObj = {
            eta: (0, _moment.default)(vesselObj.closestETA).format('YYYY-MM-DD HH:mm ') + ' ' + abbr,
            imo: vesselObj.vessel.get('imo'),
            shipname: vesselObj.vessel.get('shipname'),
            cap_teu: vesselObj.vessel.get('cap_teu')
          };
          result.push(sObj);
        });
      }
      return result;
    }),

    sortedVessels: Ember.computed('consolidatedVesselList', function () {
      return this.get('consolidatedVesselList').sort(function (a, b) {
        return a.closestETA > b.closestETA && 1 || -1;
      });
    })
  });
});