define('css2/adapters/application', ['exports', 'css2/adapters/drf', 'ember-simple-auth/mixins/data-adapter-mixin', 'css2/config/environment'], function (exports, _drf, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NO_PLURALIZATION_FOR = ['p2psearchorder', 'vesselschedule', 'servicenetwork'];

  exports.default = _drf.default.extend(_dataAdapterMixin.default, {
    applicationService: Ember.inject.service(),

    authorize: function authorize(xhr) {
      var accessToken = this.get('session.data.authenticated.access_token');
      if (accessToken) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
      }
    },


    addTrailingSlashes: true,
    pathForType: function pathForType(type) {
      if (NO_PLURALIZATION_FOR.includes(type)) {
        return type;
      }
      return this._super(type);
    },


    // increase default timeout for all requests
    ajaxOptions: function ajaxOptions(url, type, options) {
      var hash = this._super(url, type, options);
      hash.timeout = 60000;
      return hash;
    },
    ajax: function ajax(url, method) {
      var hash = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      hash.crossDomain = true;
      hash.xhrFields = { withCredentials: _environment.default.APP.USE_AJAX_CALL_WITH_CREDENTIALS };
      return this._super(url, method, hash);
    },


    host: Ember.computed(function () {
      return this.get('applicationService.apiHost');
    })
  });
});