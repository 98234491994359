define('css2/routes/portintelligence', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'moment'], function (exports, _authenticatedRouteMixin, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller) /* model */{
      this._super.apply(this, arguments);
      controller.set('minDate', new Date());
      controller.set('start', new Date());
      controller.set('selectedDate', (0, _moment.default)(new Date()).format('YYYY-MM-DD'));
    },

    resetController: function resetController(controller, isExiting /* , transition */) {
      if (isExiting) {
        controller.set('minDate', null);
      }
    }
  });
});