define('css2/controllers/login', ['exports', 'css2/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    applicationService: Ember.inject.service('application-service'),
    authenticator: 'authenticator:oauth2',
    ssoAuthenticator: 'authenticator:sso',
    jwtAuthenticator: 'authenticator:jwt',
    session: Ember.inject.service('session'),
    user: Ember.inject.service('current-user'),
    errorMessage: null,
    previousTransition: null,
    isAuthenticating: false,

    statusPageSummary: Ember.computed('componentSummary.status', function () {
      if (!this.componentSummary || this.componentSummary.status.indicator === 'none') {
        return undefined;
      }
      var filteredComponents = this.componentSummary.components.filter(function (e) {
        return e.name === 'Sailing Schedules' || e.name === 'Vessel Tracking Engine';
      });
      var sortedIncidents = this.incidentSummary.incidents.sort(function (a) {
        if (a.status === 'resolved') {
          return 0;
        }
        return -1;
      });
      return { components: filteredComponents, incidents: sortedIncidents.slice(0, 3) };
    }),

    p44LoginLink: Ember.computed(function () {
      var redirectLink = this.get('applicationService.redirectLink');
      var p44AuthSystem = _environment.default.APP.P44_LOGIN_SYSTEMS[location.host];
      if (p44AuthSystem) {
        return p44AuthSystem + '?redirect_uri=' + redirectLink;
      }
      return undefined;
    }),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var ssoCredentials = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        this.set('isAuthenticating', true);

        var _getProperties = this.getProperties('identification', 'password'),
            identification = _getProperties.identification,
            password = _getProperties.password;

        var session = this.get('session');

        // p44 auth-flow is handled in controllers/application init()
        // TODO: move saml sso-flow to controllers/application init()
        if (ssoCredentials !== null && !this.get('user.isSsoManaged')) {
          return;
        }

        // saml auth-flow
        if (ssoCredentials !== null && ssoCredentials.access_token) {
          return session.authenticate(this.get('ssoAuthenticator'), ssoCredentials).catch(function (err) {
            _this.set('errorMessage', err);
          });
        }

        // standard auth-flow
        return session.authenticate(this.get('authenticator'), identification, password).then(function () {
          _this.set('isAuthenticating', false);
          _this.get('store').findAll('incident');
          _this.get('store').findAll('carrier');
          // this.get('user').load().catch(() => this.get('session').invalidate());
        }).catch(function (error) {
          _this.set('isAuthenticating', false);
          if (error && error.error === 'invalid_grant') {
            _this.set('errorMessage', 'Username and/or password invalid!');
            // return; // uncomment when password-forgetting users overwhelm sentry ;-)
          } else if (!error) {
            _this.set('errorMessage', 'possibly a problem reaching the server.<br>Note: If you are behind a firewall requests to <b>*.project44.com <u>must</u> be granted</b>!');
            // fall-through for sentry logging, although if we really have a
            // case of "network problem" here, that will fail, too
          } else if (error && error.error === 'invalid_client') {
            _this.set('errorMessage', 'Request problem -- your network may garble requests (e.g. strip headers).');
          } else if (error.responseJSON) {
            _this.set('errorMessage', error.responseJSON.detail || error.responseJSON);
          } else {
            _this.set('errorMessage', 'unexpected error');
            console.error(error); // eslint-disable-line no-console
          }
        });
      }
    }
  });
});