define('css2/helpers/oi-comma-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.oiCommaList = oiCommaList;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function oiCommaList(params /*, hash*/) {

    if (params.length != 2) {
      return 'Illegal number of Arguments! Hint: Must be two.  ';
    }
    if (_typeof(params[0]) !== 'object' || typeof params[1] !== 'string') {
      return 'Wrong type of arguments!  ';
    } else {
      var payloadArray = params[0];
      var key = params[1];
      var returnString = '';

      payloadArray.forEach(function (elem) {
        if (key !== '' && elem[key]) {
          returnString += elem[key] + ' / ';
        } else if (key == '') {
          returnString += elem + ' / ';
        } else {
          returnString += 'WRONG KEY' + ' / ';
        }
      });
      return returnString.substr(0, returnString.length - 2);
    }
  }

  exports.default = Ember.Helper.helper(oiCommaList);
});