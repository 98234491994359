define('css2/helpers/value-or-double-dash', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.valueOrDoubleDash = valueOrDoubleDash;
  function valueOrDoubleDash(params /*, hash*/) {
    return Ember.isNone(params[0]) || params[0] === '' ? '--' : params;
  }

  exports.default = Ember.Helper.helper(valueOrDoubleDash);
});