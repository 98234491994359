define('css2/serializers/vesselschedule', ['exports', 'ember-data', 'css2/serializers/drf'], function (exports, _emberData, _drf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _drf.default.extend(_emberData.default.EmbeddedRecordsMixin, {

    isNewSerializerAPI: true,
    attrs: {
      vessel: {
        embedded: 'always'
      },
      voyages: {
        embedded: 'always'
      },
      portcalls: {
        embedded: 'always'
      }
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // as we query the record our result is expected to be an array
      // so we do some wrapping (and adding an artificial id as well)
      var newPayload = [];
      payload.id = 1;
      if (Ember.isNone(payload.vessel.id)) {
        payload.vessel.id = 1;
      } // empty result will not have a proper pk
      newPayload.push(payload);
      return this._super(store, primaryModelClass, newPayload, id, requestType);
    }
  });
});